.circa-toolbox {
    margin: 10px;

    .toolbox-header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        align-items: center;

        img {
            width: 150px;
        }
    }

    .image-wrapper {
        img {
            height: 100px;
            width: 100px;
        }
    }

    .columns {
        display: grid;
        grid-template-columns:  1fr 2fr 2fr 1fr;

        .content-card {
            height: 350px;
        }
    }
}

.toolbox-decider {
    padding: 10px;
}
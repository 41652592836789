.title {
    .A, .B, .C {
        font-weight: bold;
    }

    .A {
        fill: red;
    }

    .B {
        fill: gold;
    }

    .C {
        fill: green;
    }
}

.circle-image {
}
@import '../../../../Assets/circalind/_mixins.scss';

.lanechange {
    height: 100%;
    
    .warning {
        color: lightcoral;
        text-align: center;
    }

    .title {
        width: 100%;
        span {
            font-size: larger;
        }
        margin-bottom: 20px;
    }

    .main {
        border-radius: 15px;
        width: 80%;
        margin: 10px;
        margin-left: 10%;
        padding: 10px;
        box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
        display: grid;
        grid-template-columns: 60% auto;

        .laneInfo{
            display: grid;
            grid-template-rows: auto;
            border: 1px solid black;
            padding: 10px;
            margin-left: 10px;
            border-radius: 15px;

            button{
                height: 50px;
                width: 300px;
                padding: 5px;
                max-width: unset;
                background-color: white;
                border-radius: 10px;
                display: flex;
                line-height: 40px;

                img{
                    margin-right: 10px;
                    height: 40px;
                }
            }
        } 

        .items {
            display: grid;

            .item{
                display: flex;
                cursor: pointer;

                .image {
                    padding:5px;
                    margin: 10px;
                    border-radius: 15px;
                    box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
                    margin-left: 10px;

                    height: 80px;
                    width: 80px;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                span {
                    line-height: 100px;
                    margin-left: 100px;
                    font-size: large;
                }
            }
        }
    }
}

.transformation-compass {
    .question-table{
        .question-nav {
            margin-left: 20px;
                .nav-item{ 
                    padding:5px;
                    margin: 10px;
                    border-radius: 15px;
                    box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
                    margin-left: 10px;

                    height: 80px;
                    width: 80px;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                    &.active {
                        box-shadow: -4px 4px 8px $circa-main-blue, 4px 4px 8px $circa-main-blue;
                    }
                }

                span {
                    line-height: 100px;
                    margin-left: 100px;
                    font-size: large;
                }
            
        }
        .main{
            display: grid;
            grid-template-columns: 10% auto;
        }
        
    }

    .navigation {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-left: 150px;
        margin-right: 50px;
    }
}
@import '../../Assets/circalind/mixins';

.my-way-container {
    .row {
        display: grid;
        grid-template-columns: 20% 3% 77%;

        .image-wrapper {
            margin: 20px;
            img {
                border: 1px solid black;
                padding: 5px;
                max-width: 80%;
            }
        }

        .border-green {
            border-right: 5px solid $circa-main-green;
            margin-right: 20px;
        }
    }

    iframe {
        border-radius: 15px;
        border: none;
        width: 100%;
        height: 70vh;
    }
}
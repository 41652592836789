@import '../../Assets/circalind/mixins';

.area-header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin-left: 5px;
    text-align: center;

    .card {
        border-radius: 15px;
        border: none;
        box-shadow: 0px 0px 5px #333;
        padding: 15px;
        display: flex;
        align-items: center;
        width: 150px;

        &.active {
            box-shadow: 0px 0px 5px $circa-main-blue;
        }

        img {
            height: 40px;
            margin-right: 5px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}
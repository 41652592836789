@import '../../../Assets/circalind/mixins';

.phase-interjection {
    border-radius: 15px;
    border: none;
    box-shadow: 0px 0px 5px #333;
    height: 520px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    color: $circa-main-blue;
    position: relative;
    background-color: white;

    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: 200px;
        border-radius: 50%;
        box-shadow: 0px 0px 5px #333;
        padding: 20px;

        img {
            width: 90%;
        }
    }

    .navigation {
        width: 100%;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-left: 50px;
        margin-right: 50px;
    }

    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
}
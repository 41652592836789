/*
  Olert states being used
  InGoing         :Olert was created, Class can be animated
  OutGoing        :Olert will be deleted at end of animation
  Paused          :Olert countown is paused
  CountingDown    :Olert is counting down
  Hidden          :Olert is hidden, all olerts that would be removed will get this
*/

@import '../Assets/circalind/_mixins.scss';

.olert-container{
  box-sizing: border-box;
  position: fixed;
  top: 12px;
  right: 12px;
  width: 250px;
  z-index: 9999;

  .olert-item {
    $p: &;
    background: $circa-left-menu-background;
    color: $circa-main-blue;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;

    &.OutGoing {
      transition: transform .2s all ease-out;
      animation: olert-out .7s
    }
    
    &.InGoing {
      transition: transform .2s all ease-in;
      animation: olert-in .7s
    }
    
    &.Hidden {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 300ms, opacity 300ms;
    }

    .olert{

      .close {
        font-size: 12;
        padding: 2px;
        border: none;
        background: none;
        color: $circa-information-red;
        position: relative;
        right: -.3em;
        top: -.3em;
        float: right;

        &:hover {
          color: $circa-information-darkred;
          cursor: pointer;
        }
      }

      .olert-title{
        font-weight: bold;
      }

      .olert-progress {
        margin-top: 5px;
        height: 5px;
        background-color: $circa-main-green;
        border-radius: 10px;
      }
    }

    &.Error {
      background-color: $circa-information-red;
      color: white;
      .olert-progress {
        background-color: $circa-information-darkred;
      }
    }
  }
}


@keyframes olert-in {
	from {
	  transform: translateX(100%);
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes olert-out {
	from {
    transform: translateX(0%);
    height: 50px;
    padding: 10px;
    margin: 10px;
	}
	to {
    height: 0px;
    padding: 0px;
    margin: 0px;
	  transform: translateX(120%);
  }
}
.package-options {
    h3 {
        text-align: center;
    }

    .content-cards {
        display: flex;
        justify-content: center;
    }

    .content-cards {
        .card {
            width: 100px;
            img {
                max-height: 50px;
            }
        }
    }

    .group-logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            max-width: 200px;
        }
    }

    .allowed-project-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .preview {
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                max-width: 50%;
            }
        }

        .drop-down {
            min-width: 200px;
        }

        button {
            margin-top: 10px;
        }
    }

    .roles {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .horizontal {
        display: flex;
        width: 100%;
        justify-content: space-around;
    }

    .save-button {
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }

    margin-bottom: 10px;
}
.assistance-dialog {
    padding: 10px;

    .two-columns {
        display: grid;
        grid-template-columns:  1fr 1fr;
        gap: 20px;
    }

    .dialog-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .button-wrapper {
        display: flex;
        justify-content: end;
        margin-top: 10px;
    }
}
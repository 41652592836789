.setting-and-staffing {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 550px;

    img {
        height: 100px;
    }

    h2 {
        height: 80px;
    }

    .card .card {
        width: 80%;
    }
}
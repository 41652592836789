.contract-container {

    display: flex;
    flex-direction: column;
    margin: auto;
    width: 50%;
    margin-top: 100px;

    span {
        margin-bottom: 10px;
    }

}

.role-overview {
    .role-group {
        text-align: left;
    }

    .role-entry {
        margin-bottom: 5px;
        display: grid;
        grid-template-columns: 3fr 1fr 1fr 1fr;
        gap: 10px;
        div {
            padding-right: 10px;
            border-right: 1px solid black;
        }
    }
}

.area-overview {
    display: flex;
    flex-direction: column;
    align-items: center;

    .rooms {
        text-align: left;
        img {
            height: 1em;
        }

        .room {
            display: grid;
            grid-template-columns: 1fr 5fr;
            gap: 10px;
            margin-bottom: 5px;
        }
    }
}
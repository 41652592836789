@import '../../Assets/circalind/mixins';

.subarea-edit {
  .user-project-selection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 10px;
  }

  .area {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;

    .header-element {
      text-align: center;
      width: 150px;
      padding: 10px;

      &.selected {
        color: $circa-main-blue;
        font-weight: bold;

        .header-image {
          box-shadow: 0px 8px 10px $circa-main-blue;
        }
      }

      .header-image {
        padding: 10px 20px 10px 20px;
        border-radius: 10px;
        margin-bottom: 20px;
        box-shadow: 0px 8px 10px #aaa;
        background-color: white;
        height: 80px;

        &:hover {
          cursor: pointer;
        }

        img {
          height: 100%;
          max-width: 100%;
        }

        span {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: bold;
        }
      }
    }
  }
}
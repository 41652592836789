@import './Assets/mixins';

.impressum {
  margin-left: 15px;
  margin-right: 15px;
	text-align: center;
	color: $circa-main-blue;


  .general-info {
    display: grid;
    grid-template-columns: 60% 40%;

    //mobile
    @media only screen and (max-width: 800px) {
      grid-template-columns: 100%;
    }


    .links {
      display: flex;
      align-items: center;
      justify-content: center;

      .fingerprint {
        display: inline-flex;
        align-items: center;
      }

      img {
        height: 40px;
        padding-right: 10px;
      }

      li {
        margin-bottom: 20px;
      }

      border: 1px solid darkgrey;
      border-radius: 15px;
    }

  }

  .realization {
    margin-top: 10px;

    h4 {
      margin-top: 50px;
    }

    img {
      height: 100px;
    }
  }

  .services {
    margin-top: 80px;
  }

  ul.info {
    list-style: none;
    padding: 0;
  }

  .lindnerteam {
    margin-top: 30px;
    margin-bottom: 100px;

    .lindnerteam-list {
      display: grid;
      grid-template-columns: 25% 50% 25%;

      //mobile
      @media only screen and (max-width: 800px) {
        grid-template-columns: 33% 66%;
      }

      justify-items: center;
      align-items: center;
      text-align: left;

      img.hamburg-logo {
        max-height: 200px;
      }

      img.lindnerteam-logo {
        max-width: 250px;

        @media only screen and (max-width: 650px) {
          max-width: 90%;
        }
      }

      .lindnerteam-content-list {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  } 
}

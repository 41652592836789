@import '../../../Assets/circalind/mixins';

.transfer {

    .main {
        border-radius: 15px;
        border: 1px solid gray;
        margin: 10px;
        padding: 10px;
        height: 500px;

        .table {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

            .top {
                max-height: 150px;
            }

            .row {
                display: flex;
                width: 100%;
                height: 100%;

                .col-header {
                    margin: 10px;
                    padding: 10px;
                    box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
                    border-radius: 15px;
                    position: relative;
                    width: 100%;

                    span{
                        width: 8vw;
                        display: block;
                    }

                    img{
                        position:absolute;
                        right: 10px;
                        top: 25%;
                    }
                }

                .list {
                    margin: 10px;
                    padding: 10px;
                    border: 1px solid gray;
                    position: relative;
                    border-radius: 15px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 60%;

                    .empty {
                        position: relative;
                        top: 50%;
                        text-align: center;
                        margin-left: 25%;
                        color: lightgray;
                    }

                    .todos{
                        height: 90%;
                        width: 100%;
                        overflow-y: auto;

                        .todoItem{
                            display: grid;
                            grid-template-columns: 20% auto;
                            align-items: center;
                            margin-top: 10px;
                            cursor: pointer;
                            &.selected{
                                background-color: lightblue;
                            }

                            .state{
                                width: 2vw;
                                height: 2vw;
                                border: 1px solid darkgray;
                                border-radius: 50%;
                            }
                        }
                    }

                    .tools {
                        border: 1px solid black;
                        width: 90%;
                        text-align: center;
                        margin-top: 10px;
                        border-radius: 10px;
                        cursor: pointer;

                        &:hover {
                            background-color: $circa-main-lightblue;
                        }
                    }
                }
            }
        }
    }
}

.dialog.new-todo {
        width: 400px;
        height: 400px;

        .input-group {
            display: flex;
            align-items: center;
        }

        .selection {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;

            .delete {
                cursor: pointer;
                right: 20px;
                color: green;
                border: 1px solid darkgray;
                padding: 5px;
                border-radius: 10px;

                &:hover{
                    background-color: lightblue;
                }

                svg{
                    font-size: xx-large;
                    margin-left: 10px;
                }
            }
        }

        textarea{
            height: 12vh;
            width: 95%;
            padding: 10px;
            margin-top: 10px;
            border-radius: 10px;

            &.invalid {
                box-shadow: -2px 2px 5px red, 2px 2px 5px red;
            }
        }

        .new-navigation {
            position: absolute;
            bottom: 10px;
            width: 100%;
            display: flex;
            .left{
                button{
                    background-color: lightcoral;
                }
            }
            .right{
                right: 20px;
                position: absolute;
                
                button{
                    &:disabled {
                        background-color: lightgrey;
                        color: darkgrey;
                        cursor: unset;
                    }
                }
            }
        }
}
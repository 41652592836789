.global-dialog-container.hide {
  display: none;
}
.global-dialog-container {
  position: fixed;
  z-index: 700;
  display: grid;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-items: center;
  align-items: center;
  overflow: hidden;

  .dialog-container {
    z-index: 1;
    grid-column: 1/2;
    grid-row: 1/2;
    justify-self: center;
    align-self: center;
  }

  .dialog-overlay {
    z-index: 0;
    grid-column: 1/2;
    grid-row: 1/2;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }
}

@import '../../../Assets/circalind/mixins';

.control {
    height: 100%;

    .main {
        margin-left: 20px;

        .title {
            text-align: center;
        }

        .header {
            display: grid;
            grid-template-columns: auto auto auto;

            .left, .right{
                text-align: center;
                svg{
                    height: 50px;
                    margin: 5px;
                }
                button{
                    background-color: lightgray;
                    color: gray;
                    margin: 5px;
                    padding: 5px;
                    margin-left: 20px;
                    margin-right:20px;
                    svg{
                        height: 30px;
                    }
                }
            }

            .center {
                img{
                    height: 100px;
                }
            }
        }

        .lower-menu {
            display: grid;
            grid-template-columns:80% auto;
            margin-top: 30px;

            .new-items {
                display: grid;
                grid-template-columns: auto auto;
                .new-projects {
                    .box{
                        box-shadow: 2px 2px 20px $circa-dark-border;
                        border-radius: 15px;
                        margin: 10px;
                        padding: 10px;
                        text-align: center;
                        cursor: pointer;
                        height: 60px;
                        img{
                            height: 60px;
                        }
                    }
                }
            }

            .rows {
                button{
                    background-color: lightgray;
                    color: gray;
                    margin: 5px;
                    padding: 5px;
                    margin-left: 20px;
                    margin-right:20px;
                    height: 50px;
                    width: 60px;
                    svg{
                        height: 30px;
                    }
                }

                .row{
                    border-radius: 15px;
                    border: 1px solid black;
                    padding: 10px;
                    display: grid;
                    grid-template-columns: repeat(6, 16.67%);
                    margin-top: 20px;

                    .box{
                        box-shadow: 2px 2px 20px $circa-dark-border;
                        border-radius: 15px;
                        margin: 10px;
                        padding: 10px;
                        text-align: center;
                        cursor: pointer;
                        height: 60px;

                        img {
                            max-width: 100%;
                            height: 100%;
                        }
                    }

                    .multitext{
                        list-style: none;
                        padding-left: 0;
                    }

                    .text {
                        margin-left: 10px;
                        margin-top: 20px;
                        &.bold {
                            font-weight: bold;
                        }
                        &.right {
                            text-align: right;
                        }
                    }

                    span{
                        padding-top: 30px;
                        &.bold {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}
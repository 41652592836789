.question {
    padding: 10px;

    .question-header {
        border-bottom: 2px solid #aaa;
        max-width: 50%;
        text-align: center;
        margin: auto;
        padding-bottom: 5px;
        margin-bottom: 20px;

        input {
            width: 100%;
            text-align: center;
        }
    }
}
.synchronimeter {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 1/1;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    user-select: none;
}

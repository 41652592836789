.synchronimeter-and-controls {
    max-height: 40em;
    display: grid;
    grid-template-columns: minmax(0, 20em) minmax(0, 1fr) minmax(0, auto);
    grid-template-rows: minmax(0, auto) minmax(0, auto) minmax(0, auto) minmax(0, 1fr) minmax(0, auto);
    grid-column-gap: 2em;
    grid-row-gap: 3em;
    padding: 1em;

    grid-template-areas:
        "headline       headline headline"
        "select-is      synchro  values"
        "project        synchro  values"
        "edit-legend    synchro  values"
        "prev           synchro  next  ";

    &>.headline {
        grid-area: headline;
        justify-self: center;
        >* {
            margin: 0;
        }
    }

    &>.select-is {
        grid-area: select-is;
    }

    &>.project {
        grid-area: project;
        >button {
            max-width: 100%;
            width: 100%;
        }

        display: grid;
        grid-template-columns: minmax(0, auto);
        grid-template-rows: repeat(3, minmax(0, auto));
        grid-gap: 0.5em;
    }

    &>.edit-legend {
        grid-area: edit-legend;
    }

    &>.synchro {
        grid-area: synchro;
        overflow: hidden;
        min-height: 700px;
    }

    &>.synchro-side-by-side {
        grid-area: synchro;
        overflow: hidden;
        min-height: 700px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        h3 {
            text-align: center;
        }
    }

    &>.values {
        grid-area: values;
    }

    &>.prev {
        grid-area: prev;
        justify-self: start;
    }

    &>.next {
        grid-area: next;
        justify-self: end;
    }

}

.new-staffing-project {
    min-width: 500px;
    .inputs {
        .input {
            display: flex;
            flex-direction: column;

            input {
                margin-right: 0;
            }
        }

        .users {
            .add-section {
                display: flex;
                .add-user {
                    margin-bottom: 10px;
                    width: 100%;
                }

                .add-role {
                    margin-left: 10px;
                    width: 100%;
                }
            }

            .list {
                margin-top: 10px;
                margin-bottom: 10px;

                .list-item {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 20px;
                    gap: 5px;

                    .fa-trash-can:hover {
                        cursor: pointer;
                    }
                }
            }

            .add-button-container {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
            }
        }
    }

    .buttons {
        margin-top: 30px;
        display: flex;
        width: 100%;
        justify-content: flex-end;

        button {
            margin-left: 10px;
        }
    }
}

.project-family-overview {
    padding: 10px;

    .button-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        img {
            height: 75px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .projects {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .project {
            display: flex;
            flex-direction: row;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 10px;

            .project-name {
                display: flex;
                align-items: center;
            }

            .main-project {
                margin-left: 10px;
            }
        }
    }

    .icon {
        margin-left: 5px;
        &:hover {
            cursor: pointer;
        }
    }

    .family {
        padding: 10px;

        .actions {
            display: flex;
            justify-content: end;
            width: 100%;
        }
    }

    .family-dialog {
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .project-dialog {
        overflow: visible;

        > div:not(:first-child) {
            padding: 20px;
            display: grid;
            min-width: 30rem;
            grid-template-columns: 1fr auto;
            align-items: center;
        }

        button {
            margin-left: 10px;
        }
    }

    .navigation {
        margin-top: 10px;
    }
}

.status-circle {
    margin-right: 5px;
}
@import '../Assets/circalind/_mixins.scss';

.App-Circalind {
	height: 100%;
	@media only screen and (max-width: 500px) {
		height: unset;
	}

	input {
		border: $circa-input-border 2px solid;
	}

	button {
		background-color: white;
		color: $circa-default-text;
		border-radius: 10px;
		font-size: inherit;

		border: none;
		box-shadow: 0px 0px 5px #333;

		&:hover {
			background-color: $circa-main-lightblue;
		}

		&:disabled {
			color: lightgrey;
			cursor: not-allowed;
		}

		&:disabled:hover {
			background-color: white;
		}
	}

	label {
		color: $circa-label-text;
	}

	.app-body {
		margin-bottom: 0;
		height: calc(100% - 140px);
		overflow-y: auto;
		overflow-x: hidden;

		@media only screen and (max-width: 500px) {
		height: 100%;
		max-width: 100%;
		}
	}
}

.hidden {
	display: none;
}

.grid {
	display: grid;
}

.manager-customer {
    margin: 10px auto 10px auto;
    max-width: 800px;

    .right {
        margin-top: 10px;

        button {
            margin-left: 10px;
        }
    }
}
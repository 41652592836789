.link-project {
    margin: 15px;
    .project-selection {
        display: flex;
        align-items: center;

        button {
            margin-left: 5px;
        }
    }
}

.project-history {
    display: flex;
    flex-direction: column;
}
@import '../Assets/circalind/mixins';

.full-page {
    border-radius: 15px;
    border: none;
    box-shadow: 0px 0px 5px #333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $circa-main-blue;
    position: relative;
    background-color: white;
    min-height: 520px;
    margin-bottom: 25px;
    text-align: center;

    img {
        height: 200px;
    }


    .lower-arrow {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 24px;
        color: darkgray;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 5px;
        border-radius: 10px;
        width: 50px;
        text-align: center;

        &:hover {
            cursor: pointer;
        }
    }

    .navigation {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 100px);
    }
}
.compare-synchronimeters {
    .synchronimeters {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 2fr 2fr;

        .synchro {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: start;
            box-sizing: border-box;

            h3 {
                text-align: center;
            }
        }
    }
}
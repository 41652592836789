@import '../../../Assets/_mixins.scss';

.hybrid-base64-upload {
  .hybrid-base64-upload-drop-area {
      border: 3px dashed $circa-main-blue;
      color: $circa-main-blue;
      height: 100px;
      display: grid;
      justify-content: center;
      align-content: center;

    cursor: pointer;
  }

  .hybrid-base64-upload-input {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
    overflow: hidden;
    position: fixed;
  }
}

.info-block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .info-card {
        border-radius: 15px;
        padding: 15px;
        margin: 15px;
        box-shadow: 0px 8px 10px #aaa;

        .info-image {
        border-radius: 15px;
            width: 80px;
            height: 80px;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 8px 10px #aaa;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;

            img {
                height: 100%;
            }
        }
    }
}
.profile {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
}

.profile-group {
    display: grid;
    grid-template-columns: 30% auto;
}

.profile-group-header {
    margin-right: 1.5rem;
}

.profile-block {
    display: grid;
}

.profile-block label {
    color: var(--color-main-blue)
}

.profile-sub-block {
    display: grid;
}

.contract-block{
    display: grid;
    grid-template-columns: 50% 50%;
}

.contract-block span{
    margin: 5px;
}

.desc {
    color: var(--color-main-blue)
}

input[type=color] {
    padding: 0;
}
html {
  height: 100%;

  #root {
    height: 100%;
  }

  .App {
    text-align: center;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
  }

  .app-body {
    width: 100%;
    margin-bottom: 50px;
    height: 100%;
  }
}

.order-overview-container {
    .customer-select-wrapper {
        display: grid;
        grid-template-columns: 3fr  1fr;
        margin-bottom: 10px;
        align-items: center;
        gap: 10px;
    }

    button {
        max-width: unset;
    }
}

.order-payment-dialog {
    padding: 25px;

    .top-row {
        margin-bottom: 10px;
    }
}

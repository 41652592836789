@import '../../../Assets/circalind/_mixins.scss';


.phase-radar {

    .navigation {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-left: 50px;
        margin-right: 50px;
    }

    .radar {
        display: grid;
        grid-template-columns: 10% auto;
        .drivers {
            text-align: center;
            h2{
                min-height: 80px;
            }
            .select{
                .image-select{
                    display: grid;
                    img{
                        padding: 5px;
                        width: 50px;
                        height: 50px;
                        margin-left: auto;
                        margin-right: auto;
                        cursor: pointer;
                        &.selected {
                            border-bottom: 2px solid $circa-main-blue;
                        }
                    }
                }
            }
        }
        .driver-phases {
            margin-left: 10px;
            margin-right: 10px;
            display: grid;
            grid-template-columns: repeat(5,20%);
            .driver {
                max-width: 100%;
                display: grid;
                margin: 10px;
                //height: 70vh;
                row-gap: 10px;
                .title {
                    font-weight: bold;
                }
                .text {
                    min-height: 200px;
                    resize: none;
                    padding: 5px;
                    border-radius: 15px;
                }
                .img-container {
                    text-align: center;
                    margin-top: 20px;
                    &.empty {
                        background-color: lightgray;

                    }
                    img {
                        width: 100%;
                    }
                    min-height: 200px;
                }
            }
        }
    }
}
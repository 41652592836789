@import '../../../Assets/circalind/mixins';

.navigation {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.practical-check {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .card {
        box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
        border-radius: 15px;
        padding: 20px;
        text-align: left;
        margin: 20px;
        display: grid;
        grid-template-rows: 1fr 3fr 1fr;
        align-items: center;
        height: 60vh;

        .img-card-wrapper {
            display: flex;
            justify-content: center;

            .img-card {
                box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
                border-radius: 15px;
                padding: 20px;
                height: 50px;
                width: 40%;
                display: flex;
                justify-content: center;

                img {
                    height: 100%;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .checkbox-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            input[type=checkbox] {
                transform: scale(2);
                accent-color: $circa-main-green;
            }
        }
    }
}

@import '../../Assets/circalind/_mixins.scss';

.info-card {
    padding: 2em;

    .three-column-layout {
        color: white;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 25px;
        width: 70vw;

        .planning {
            background-color: $circa-main-blue;
        }
        .construction {
            background-color: $circa-main-lightblue;
        }
        .simulation {
            background-color: $circa-main-green;
        }

        .planning, .construction, .simulation {
            display: flex;
            font-weight: bold;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 15px;
            border-radius: 15px;
            white-space: pre-line;
        }
    }
}

.phase-diagram {
    .graph-items {
        display: flex;

        .graph-legend {
            overflow-y: auto;
            ul{
                margin-left: 1px;
                li {
                    display: flex;
                    margin-bottom: 5px;
                    svg{
                        cursor: pointer;
                    }
                }
            }
            .point-legend {
                text-align: left;
                margin-top: 15px;
            }
            .color-preview {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 10px;
            }
            .color-input {
                width: 20px;
                border: unset;
                padding: unset;
                margin: unset;
                border-radius: 15px;
            }

            input{
                margin: 2px;
                padding: 2px;
                width: 150px;
                border: 1px solid;
            }
        }
        .question-graph {
            display: grid;
            grid-template-rows: 1fr 8fr;
            gap: 10px;

            margin-top: 30px;
            width: 550px;
            user-select: none;

            .graph-title-input {
                margin-bottom: unset;
            }
        }
    }
}
@import '../../../../Assets/circalind/mixins';

.cockpit-overview {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 500px;

    .clickable {
        cursor: pointer;
    }

    .content-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        
        h3 {
            text-align: center;
            width: 100%;
        }

        .image-wrapper {
            display: flex;
            justify-content: center;
            img {
                max-width: 75%;
            }
        }

        .image-with-text {
            padding: 5px;
            border: 2px solid $circa-main-blue;
            display: flex;
            flex-direction: column;
            .image-row {
                display: flex;
                justify-content: flex-end;
                img {
                    width: 33%;
                }
            }
        }

        &.show-back {
            transform: rotateY(180deg);
            transition: transform 0.8s;
            transform-style: preserve-3d;

            .front, .back {
                transform: rotateY(180deg);
            }
        }

        &.show-front {
            .back {
                visibility: hidden;
            }
        }
    }
    perspective: 1000px;
}

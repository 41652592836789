$circa-default-text: black;
$circa-label-text: #C4C4C4;
$circa-grey-text: #5e5e5eff;

$circa-main-blue: #175583;
$circa-main-lightblue: #70BDE5;
$circa-main-green: #A1C74A;
$circa-main-radius: #43728b;

$circa-footer-background: white;

$circa-menu-background: #f9f9f9;
$circa-menu-border: #d6d6d6;

$circa-dark-border: #929292;
$circa-dark-grey: #4b4b4bff;

$circa-background-arrow: #f7f7f7;

$circa-information-red: #e95329;
$circa-information-darkred: #a72f62;
$circa-information-blue: #1e6eac;
$circa-information-lightblue: #70bde5;
$circa-information-orange: #fbbc00;
$circa-information-lightgreen: #99c22e;
$circa-information-green: #009149;

$circa-left-menu-background: #c0e4ff;

$circa-notes-border: #eeecec;
$circa-notes-background: #eeecec;

$circa-input-border: #175583;
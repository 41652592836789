.personal-dashboard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .main {
        height: 500px;
    }

    .img-card img {
        height: 100px;
    }
}
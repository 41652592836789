@import '../../Assets/circalind/mixins';

.staffing {

    .list-cards {
        display: grid;
        grid-auto-flow: column;
        min-height: 500px;

        .list-card {
            display: flex;
            flex-direction: column;
            border-radius: 15px;
            border: none;
            box-shadow: 0px 0px 5px #333;
            margin: 5px;
            padding: 5px;
            text-align: center;

            .user-roles {
                width: 100%;
                text-align: center;
                display: block;
                margin-top: 5px;
            }

            .split-select {
                margin-top: 5px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 5px;
            }

            .center-criteria {
                display: block;
                width: 100%;
                text-align: center;
            }

            .title {
                display: block;
                margin-bottom: 10px;

                .details {
                    border: 1px solid black;
                    padding: 5px;
                }
            }

            h3 {
                color: $circa-main-blue;
            }

            .list {
                padding: 5px;
                height: 300px;
                overflow-y: auto;
                text-align: left;

                .list-entry {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;

                    .svg-inline--fa {
                        cursor:pointer;
                    }

                    .project-entry, .user-entry, .group-entry {
                        display: grid;
                        padding: 5px;
                        margin-right: 5px;
                        width: 100%;
                        border-radius: 15px;
                        border: none;
                        box-shadow: 0px 0px 5px #333;
                        cursor: pointer;

                        label{
                            cursor: pointer;
                            margin: 0px;
                        }
                    }

                    .project-entry {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                    }
                }
            }

            .add{
                width: 100%;
                max-width: unset;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .permission-list {
                margin-top: 10px;
                display: grid;
                grid-template-columns: auto auto;

                .centered {
                    text-align: center;
                }

                .permission {
                    display: flex;
                    align-items: center;

                    img {
                        width: 20px;
                        margin-right: 5px;
                    }
                }
            }

            .user-counts {
                .user-count {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            }
        }

        hr {
            color: hsl(0, 0%, 80%);
        }
    }

    .detailProject {
        width: 90%;
        height: 90%;
        overflow-y: auto;

        .projects-list {
            display: grid;
            grid-template-columns: 50% 50%;
            align-content: flex-start;
    
            .project-item {
                border-radius: 15px;
                border: 1px solid gray;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: repeat(3, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                margin: 10px 40px 10px 40px;
                height: 200px;
    
                img {
                    height: 50px;
                }
    
                .part {
                    border: 1px solid gray;
                    text-align: center;
                    display: table;
    
                    span {
                        display: table-cell;
                        vertical-align: middle;
                    }
    
                    &.item-1 {
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                        text-align: left;
                        cursor: pointer;
                        grid-area: 1 / 1 / 2 / 5;
    
                        span {
                            padding-left: 20px;
                            font-size: x-large
                        }
                    }
    
                    &.item-2 {
                        grid-area: 2 / 1 / 3 / 2;
                    }
    
                    &.item-3 {
                        grid-area: 2 / 2 / 3 / 3;
                    }
    
                    &.item-4 {
                        grid-area: 3 / 1 / 4 / 3;
                        border-bottom-left-radius: 15px;
    
                        svg {
                            height: 50px;
                        }
                    }
    
                    &.item-5 {
                        grid-area: 2 / 3 / 4 / 4;
                    }
    
                    &.item-6 {
                        grid-area: 2 / 4 / 4 / 5;
                        border-bottom-right-radius: 15px;
    
                        img {
                            margin-top: 20px;
                            height: 100px;
                        }
                    }
                }
            }
        }
    }

    .tool-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        margin-top: 10px;

        .area-name {
            img {
                width: 20px;
                margin-right: 5px;
            }
        }
    }
}

.phase-diagrams {
    .graph-config {
        display: grid;
        grid-auto-flow: column;
        gap: 10px;
        align-items: center;
        justify-content: end;
    }

    .graphs {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .buttons {
        display: grid;
        grid-auto-flow: column;
        justify-content: end;
        gap: 10px;
    }
}
@import '../../Assets/circalind/mixins';

.pre-login {
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;

    .navigate {
        width: 100%;
        display: inline-flex;
        .nav-arrow {
            margin-top: 20%;
            margin-bottom: auto;
            font-size: 46px;
            color: $circa-main-blue;
            margin-left: 20px;
            cursor: pointer;
            position: absolute;
            right: 50px;
        }
        .pages {
            width: 100%;

            .page{

                &.pos-1 {
                    .header {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        height: 100%;

                        img.lindner-team-logo {
                          max-width: 250px;
                        }
                    }

                    border-color: $circa-main-blue;
                    .items{
                        padding: 10px;
                        display: grid;
                        grid-template-columns: 100%;

                        //Desktop
                        @media only screen and (min-width: 1100px) {
                            grid-template-columns: 33% 33% 33%;
                        }

                        .scene {
                            perspective: 680px;
                            width: 60vw;
                            padding: 20px;
                            height: 680px;

                            //Desktop
                            @media only screen and (min-width: 1100px) {
                                width: 20vw;
                            }

                            @media only screen and (max-width: 750px) {
                                width: unset;
                            }

    
                            .project-card {
                                width: 100%;
                                height: 100%;
                                transition: transform 1s;
                                transform-style: preserve-3d;
                                cursor: pointer;
                                position: relative;
                                
    
                                &.is-flipped {
                                    transform: rotateY(180deg);
                                }
    
                                .card {
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    color:  $circa-main-blue;
                                    text-align: center;
                                    font-weight: bold;
                                    -webkit-backface-visibility: hidden;
                                    backface-visibility: hidden;
                                    border-radius: 15px;
                                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                                }
    
                                .front {
                                    transform: rotateY(0deg);
                                    display: grid;
                                    grid-template-rows: 10% auto;
                                    margin-top: 0;
                                    padding: 10px;
                                
                                    .lower-arrow {
                                        position: absolute;
                                        bottom: 10px;
                                        right: 5%;
                                        font-size: 24px;
                                        color: darkgray;
                                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                                        padding: 5px;
                                        border-radius: 10px;
                                        width: 15%;
                                    }
    
                                    img {
                                        position: absolute;
                                        bottom: 70px;
                                        width: 100%;
                                        left: 10%;

                                        &.lower{
                                            bottom: 10px;
                                        }

                                        &.left{
                                            left: 0;
                                        }

                                        &.right{
                                            right: 0;
                                            left: unset;
                                        }
    
                                        //Tablets
                                        @media only screen and (max-width: 1100px) {
                                          width: 60%;
                                          left: 35%;
                                        }

                                        //Tablets
                                        @media only screen and (max-width: 850px) {
                                          width: 80%;
                                          left: 25%;
                                        }
                                    }
                                }
    
                                .back {
                                    transform: rotateY(180deg);
                                    justify-content: start;
                                    align-items: center;
                                    text-align: left;
                                    padding: 10px;
                                    color: black;

                                    .back-title {
                                      display: flex;
                                      align-items: center;
                                      color:  $circa-main-blue;
                                      height: 80px;

                                      img {
                                        height: 60px;
                                        margin-right: 10px;
                                      }
                                    }
                                    .back-content {
                                      margin-top: 30px;
                                      font-weight: normal;

                                      .sub-header{
                                        font-weight: bold;
                                        color:  $circa-main-blue;
                                      }
                                    }

                                    .back-arrow{
                                        position: absolute;
                                        bottom: 10px;
                                        display: flex;
                                        width: 100%;

                                        span{
                                            margin-bottom: 20px;
                                            font-weight: 100;
                                        }

                                        .lower-arrow {
                                            text-align: center;
                                            position: absolute;
                                            bottom: 10px;
                                            right: 5%;
                                            font-size: 24px;
                                            color: darkgray;
                                            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                                            padding: 5px;
                                            border-radius: 10px;
                                            width: 15%;
                                        }
                                    }
                                }
    
                            }
                        }
                    }
                }

                &.pos-2 {
                    border-color: $circa-main-green;

                    .header {
                        height: 100%;
                    }

                    .sub-header {
                        margin-bottom: 10px;
                    }

                    .items{
                        padding: 10px;
                        display: grid;
                        grid-template-columns: 100%;
                        grid-template-rows: auto;

                        //Desktop
                        @media only screen and (min-width: 1100px) {
                            grid-template-columns: 33% 33% 33%;
                        }

                        .philosophy {
                            color: white;
                            width: 100%;
    
                            .header {
                                &.blue {
                                    background-color: $circa-main-blue;
                                }
                                &.green {
                                    background-color: $circa-main-green;
                                }
                                &.light {
                                    background-color: $circa-main-lightblue;
                                }

                                display: flex;
                                justify-content: center;
                                align-items: center;
    
                                border-radius: 15px;
                                color: white;
                                margin: 10px;
                                padding: 20px;
                                cursor: pointer;
                                height: 60px;

                                //Desktop
                                @media only screen and (min-width: 1100px) {
                                    padding: 10px;
                                }

                            }
    
                            .info {
                                border-radius: 15px;
                                color: white;
                                margin: 10px;
                                padding: 10px;
                                text-align: left;
                                overflow: hidden;

                                &.blue {
                                    color: black;
                                    border: 1px solid $circa-main-blue;
                                }
                                &.green {
                                    color: black;
                                    border: 1px solid $circa-main-green;
                                }
                                &.light {
                                    color: black;
                                    border: 1px solid $circa-main-lightblue;
                                }
                                
                                //Desktop
                                @media only screen and (min-width: 1100px) {
                                    font-size: 0.95vw;
                                    height: calc(100% - 120px);
                                }
    
                                &.expanded {
                                    animation: expand;
                                    animation-duration: 0.5s;
                                    max-height: 1000px;
                                }
    
                                &.collapsed {
                                    max-height: 0px;
                                    animation: collapse;
                                    animation-duration: 0.5s;
                                    visibility: hidden;
                                }
    
                                ul {
                                    list-style-type: '-  ';
                                }

                                span{
                                    height: 100%;
                                    display: inline-block;
                                }
                            }
    
                            @keyframes expand {
                                0% {
                                    max-height: 0px;
                                }
                                100% {
                                    max-height: 100%;
                                }
                            }
    
                            @keyframes collapse {
                                0% {
                                    visibility: unset;
                                    max-height: 100%;
                                }
                                100% {
                                    max-height: 0px;
                                    visibility: hidden;
                                }
                            }
    
                        }
                    }
                }

                &.pos-3 {
                    border-color: $circa-main-lightblue;

                    .header {
                        height: 100%;
                        margin: 10px;
                    }

                    .sub-header {
                        margin: 10px;
                    }

                    .cards {
                      display: grid;
                      grid-template-columns: 33% 33% 33%;
                      margin-left: 10px;
                      margin-right: 10px;

                        //Desktop
                        @media only screen and (max-width: 1000px) {
                          display: none;
                        }


                      .card {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px;
                        margin-right: 10px;
                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                        border-radius: 15px;
                        color:  $circa-main-blue;

                        img {
                          width: 40%;
                          margin-bottom: 10px;
                        }
                      }
                    }

                    .items{
                        padding: 10px;
                        display: grid;
                        grid-template-columns: 33% 33% 33%;

                        @media (max-width: 1100px) {
                            grid-template-columns: 100%;
                        }

                        .inputs {
                            display: flex;
                            flex-direction: column;
                            text-align: left;

                            .send-button {
                                width: calc(100% - 10px);
                            }

                            input {
                                height: 35px;
                                border-radius: 15px;
                                padding: 5px;
                                margin: 5px 10px 0px 0;
                                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                            }
                            label {
                                height: 20px;
                                border-radius: 15px;
                                padding: 5px;
                                margin: 5px 10px 0px 0;
                                color: black;
                            }
                            select {
                                height: 49px;
                                border-radius: 15px;
                                padding: 5px;
                                margin: 5px 10px 5px 0;
                                background-color: white;
                                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                            }
                            button {
                                height: 49px;
                                border-radius: 15px;
                                padding: 5px;
                                margin: 5px 10px 5px 0;
                                max-width: unset;
                                width: 100%;
                                color: white;
                                background-color: $circa-main-blue;
                                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                            }
                            .alert {
                                background-color: lightcoral;
                                border-radius: 5px;
                            }
                            .dataprivacy {
                                display: grid;
                                grid-template-columns: 10% auto;
                                height: 59px;
                                label{
                                    margin-top: 10px;
                                    font-size: 12px;
                                }
                                input {
                                    box-shadow: none;
                                }
                            }
                        }
                    }

                    .privacy {
                        display: flex;
                        text-align: unset;

                        .privacy-info{
                            display: grid;
                            align-items: center;
                            margin-left: auto;
                            margin-right: auto;
                            
                            //Desktop
                            @media only screen and (min-width: 768px) {
                                margin-left: auto;
                                margin-right: 10px;
                            }

                            img{
                                height: 4vh;
                            }
                        }
                    }
                }

                box-shadow: 4px -2px 0px 2px white,
                            8px -4px 0px 4px $circa-main-green,
                            12px -6px 0px 6px white,
                            16px -8px 0px 8px $circa-main-lightblue;

                margin: 30px;
                border-radius: 15px;
                border: 4px solid $circa-main-blue;
                background-color: white;
                width: 80%;
                margin-left: auto;
                margin-right: auto;

                @media only screen and (max-width: 750px) {
                    width: 90%;
                    margin: unset;
                    margin-top: 30px;
                    margin-bottom: 30px;
                }

                .header {
                    text-align: center;
                    display: grid;
                    padding: 10px;
                    font-weight: bold;
                    color: $circa-main-blue;
                    //height: 10vh;
                    cursor: pointer;

                    @media (max-width: 1100px) {
                        height: 5vh;
                    }

                    &span {
                        margin-left: auto;
                        margin-right: auto;
                        max-width: 30vw;
                        border: 2px solid $circa-main-blue;
                        border-radius: 50%;
                        padding: 25px;
                    }
                }

                .sub-header {
                    display: inline-grid;
                    color: $circa-main-blue;
                }

                .impulses {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin: 10px;

                    .impuls-text {
                        padding: 5px;
                        text-align: left;
                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                        border-radius: 15px;
                        width: 18vw;
                    }

                    .impuls {
                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                        border-radius: 15px;
                        width: 12vw;
                        span{
                            position: relative;
                            bottom: 30%;
                        }
                    }
                }
            }
            
        }
    }
}

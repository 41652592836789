.customer-container {
    width: 100%;

    .form-entry {
        width: 100%;
        display: flex;
        flex-direction: column;

        input {
            width: 100%;
            box-sizing: border-box;
        }
    }
}
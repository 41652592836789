@import '../../../Assets/circalind/mixins';

.menu-container {
  display: flex;
  flex-direction: column;

  .no-data {
    padding: 25px;
  }

  .bottom-content {
    display: flex;
    width: 100%;

    .main-content {
      width: 100%;

      .top-row {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 10px;

        .sub-menu {
          display: grid;
          grid-template-columns: 1fr 5fr 1fr;
          align-items: center;
          width: 100%;
          text-align: center;

          .sub-menu-svg {
            max-width: 150px;
          }

          .sub-menu-entries {
            a {
              color: grey;
              text-decoration: none;
              margin-left: 15px;
            }

            a.selected-item {
              font-weight: bold;
              color: black;
            }
          }
        }

      }
    }

    .side-menu-container {
      border: 1px solid black;
      border-radius: 15px;
      display: flex;
      align-items: stretch;
      margin: 5px;
      background-color: white;

      .side-menu {
        display: flex;
        flex-direction: column;
        padding-left: 5px;
        padding-right: 5px;
        width: 100px;

        &.hide {
          visibility: hidden;
          width: 0;
          padding: 0;
        }

        border-right: 1px dashed black;

        img {
          margin-top: 5px;
          padding: 5px;

          &:hover {
            cursor: pointer;
          }

          &.inactive {
            filter: opacity(30%);
          }

          &.disabled {
            filter: opacity(0%);
            cursor: unset;
          }
        }

        hr {
          width: 80%;
        }
      }

      .left-expand-area {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .change-area {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 10px;


      .change-area-border {
        background-color: white;
        border: 1px solid black;
        border-radius: 15px;
        display: grid;
        grid-template-columns: 20px auto;
        overflow: hidden;

        .left-expand-area {
          border-right: 1px dashed black;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            cursor: pointer;
          }

          &.hide {
            border: none;
          }
        }

        .right-expand-area {
          display: flex;
          flex-direction: column;

          &.hide {
            visibility: hidden;
            width: 0;
          }

          .change {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;

            h4 {
              text-align: center;
              margin-bottom: 0;
              margin-top: 10px;
            }

            .image-card {
              border-radius: 50%;
              background-color: white;
              padding: 15px;
              box-shadow: 0px 8px 10px #aaa;
              height: 60px;
              width: 60px;
              display: flex;
              justify-content: center;

              &:hover {
                cursor: pointer;
              }

              img {
                height: 100%;
                max-width: 100%;
                &.inactive {
                  filter: opacity(30%);
                }
              }
            }
          }
        }
      }
    }
  }

  .file-save-dialog {
    .form-input {
      padding: 25px;
    }
  }

  .leading-header {
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
  }
}

.management-phases {
  .management-phase-group {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    border: 1px solid black;
    padding: 5px;
    margin-left: 5px;
  }

  .management-phase-group-elements {
    display: flex;
    justify-content: flex-end;
  }

  .management-phase {
    margin-right: 10px;
    display: flex;
    align-items: center;

    img {
      height: 20px;
      margin-left: 5px;
    }

    &:hover {
      cursor: pointer;
    }
    &.selected-phase {
      font-weight: bold;
    }
  }
}
@import '../../Assets/circalind/_mixins.scss';

.leading-info-overview-grid {
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 0.5em;

    grid-template-areas:
        "l1 cc r1"
        "l2 cc r2"
        "l3 cc r3"
        "l4 cc r4"
        "l5 cc r5"
        "l6 cc r6"
        "l7 cc r7";

    >*:nth-child(1) { grid-area: l1; justify-self: left;}
    >*:nth-child(2) { grid-area: l2; justify-self: left;}
    >*:nth-child(3) { grid-area: l3; justify-self: left;}
    >*:nth-child(4) { grid-area: l4; justify-self: left;}
    >*:nth-child(5) { grid-area: l5; justify-self: left;}
    >*:nth-child(6) { grid-area: l6; justify-self: left;}
    >*:nth-child(7) { grid-area: l7; justify-self: left;}

    >*:nth-child(8)  { grid-area: r1; justify-self: right;}
    >*:nth-child(9)  { grid-area: r2; justify-self: right;}
    >*:nth-child(10) { grid-area: r3; justify-self: right;}
    >*:nth-child(11) { grid-area: r4; justify-self: right;}

    >.leading-info-center {
        grid-area: cc;
        justify-content: center;;
        justify-self: center;
        align-self: center;

        display: grid;
        grid-template-columns: minmax(0, 10em);
        grid-gap: 1em;
        grid-row-gap: 3em;

        >* {
            justify-self: center;
        }

        >*:nth-child(1) {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          grid-template-rows: auto minmax(0, 1fr);
          border-radius: 3px;
          height: 15em;
          font-size: 0.8em;
          padding: 0.5em;
          box-shadow: 0 0 0.5em 0.1em rgba(0,0,0,0.3);
          margin: 0 2em;

          >*:nth-child(1) {
            align-self: end;
          }
        }

        img {
          width: 100%;
        }
    }

}


@import '../../Assets/circalind/mixins';

.leading-documentation {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 500px;

    .clickable {
        cursor: pointer;
    }

    .content-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .front {
            width: 100%;
        }
        
        h3 {
            text-align: center;
            width: 100%;
        }

        .image-wrapper {
            display: flex;
            justify-content: center;
            height: 80px;

            img {
                height: 90%;
            }
        }

        .image-with-text {
            padding: 5px;
            border: 2px solid $circa-main-blue;
            display: flex;
            flex-direction: column;
            .image-row {
                display: flex;
                justify-content: flex-end;
                img {
                    width: 33%;
                }
            }
        }

        &.show-back {
            transform: rotateY(180deg);
            transition: transform 0.8s;
            transform-style: preserve-3d;

            .front, .back {
                transform: rotateY(180deg);
            }
        }

        &.show-front {
            .back {
                visibility: hidden;
            }
        }
    }
    perspective: 1000px;

}

.download-container {
    align-items: center;
    display: flex;
    flex-direction: column;

    .selection {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    button {
        margin-top: 10px;
    }
}
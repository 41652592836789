.project-dashboard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    h3, h4 {
        text-align: center;
    }

    .header-card {
        display: flex;
        justify-content: start;
        height: 700px;

        img {
            height: 50px;
        }
    }

    .img-card {
        img {
            height: 50px;
        }
    }

    .clickable {
        &:hover {
            cursor: pointer;
        }
    }
}
@import '../../Assets/circalind/_mixins.scss';

.login {
    display: grid;
    text-align: center;

    .login-form {
        width: fit-content;
        margin: auto;
        display: grid;

        input {
            display: block;
            width: 400px;
            margin-left: auto;
            margin-right: auto;

            &.invalid {
                box-shadow: -2px 2px 5px $circa-information-red, 2px 2px 5px $circa-information-red;
            }
        }

        button {
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;

            &.disabled {
                background-color: lightgray;
                cursor: default;
            }
        }

        label {
            cursor: pointer;
        }
    }
}

.update {

    display: grid;
    text-align: center;

    .reset-password{
        display: grid;

        input {
            display: block;
            width: 400px;
            margin-left: auto;
            margin-right: auto;

            &.invalid {
                box-shadow: -2px 2px 5px $circa-information-red, 2px 2px 5px $circa-information-red;
            }
        }

        button {
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;

            &:disabled {
                background-color: lightgray;
                cursor: default;
            }
        }
    }

    .warning {
        display: grid;

        .invalid {
            color: $circa-information-red;
            font-weight: bold;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}
.card {
    border-radius: 15px;
    border: none;
    box-shadow: 0px 0px 5px #333;
    padding: 15px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.area-header {
    display: flex;
    justify-content: center;
    padding: 15px;
    text-align: center;
    flex-wrap: wrap;

    .card {
        img {
            height: 40px;
            margin-bottom: 10px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.phases {
    display: flex;
    justify-content: space-around;
    padding: 15px;

    .card {
        display: flex;
        justify-content: center;
        height: 250px;
        text-align: center;
        width: 20%;

        img {
            height: 80px;
            margin-bottom: 15px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

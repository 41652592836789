$invoice-default-text: black;

$invoice-button-background: var(--primary);
$invoice-button-border: #9e9e9e;
$invoice-button-color: #ffffff;
$invoice-button-color-dissabled: #858585;
$invoice-button-text-shadow: #b8b8b8;
$invoice-button-disabled: #9e9e9e;
$invoice-button-hover: #d1d1d1;

$invoice-footer-text: white;

$invoice-header-text: var(--primary);
$invoice-header-background: white;

$invoice-menu-item-border: lightgray;
$invoice-menu-item-hover: gray;

$invoice-invoice-data: #b8b8b8;
$invoice-invoice-state-background: #d3d3d3;

$invoice-menu-dropDown-background: #f1f1f1;
$invoice-menu-dropDown-hover: #7a7a7a;
$invoice-menu-dropDown-content: #f1f1f1;

$invoice-olert-close-button: darkgray;
$invoice-olert-background: lightgray;
$invoice-olert-progress: gray;

$invoice-table-th: #9e9e9e;
$invoice-table-tr-odd: #dedede;
$invoice-table-tr-hover: #b8b8b8;
$invoice-table-tr-checked: #93c2ff;
$invoice-table-tr-checked-odd: #bcd9ff;
$invoice-table-button: #f1f1f1;
$invoice-table-nav: #d1d1d1;
$invoice-table-invoice-state: black;
$invoice-table-checked: #2196F3;

$invoice-invalid-background: lightcoral;
$invoice-delete: #ff3f3f;

$invoice-state-1: lightgrey;
$invoice-state-2: lightcoral;
;
$invoice-state-3: gray;
$invoice-state-4: #537fd4;
$invoice-state-5: green;

$invoice-search-color: #666666;
$invoice-search-border: #2d2d2d;

$invoice-check-background: #eee;
$invoice-check-hover-background: #ccc;
$invoice-check-container-border: white
@import '../../../Assets/circalind/mixins';

footer {
	//background-color: $circa-footer-background;

	.circalind-footer {
		display: flex;
        align-items: center;
		background-size: 100%;
		

    @media only screen and (max-width: 500px) {
      justify-content: center;
      flex-direction: column;
    }

		img {
			max-width: 200px;
      margin-right: 15px;

      @media only screen and (max-width: 500px) {
        max-width: 230px;
        margin-right: 0px;
        margin-bottom: 15px;
      }
		}

		.bands {
			width: 100%;

			.footer-bands {
				height: 50px;
				width: 100%;
			}

			@media only screen and (max-width: 1000px) {
				display: none;
			}
		}

		.item {
			color: $circa-main-blue;
			text-decoration: none;
			margin-top: auto;
			margin-bottom: auto;

			&:hover {
				color: $circa-main-lightblue;
			}
		}

		.links {
			display: inline-grid;
		}

		.services {
			grid-area: 2 / 1 / 3 / 3;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-left: 20px;
			margin-right: 20px;

			a {
				text-decoration: none;
				color: $circa-main-blue;

				&:hover {
					color: $circa-main-lightblue;
				}
			}
		}
	}

	.imprint-data {
		margin-top: 5px;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		a {
			margin-right: 10px;
		}
	}
}

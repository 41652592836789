.Square-inputs {
    margin-left: 50px;
    .color-inputs {
        margin: 20px;
        .color-row {
            display: grid;
            grid-template-columns: 30% auto auto auto auto;
            gap: 15px;
            text-align: left;

            input{
                padding: unset;
            }
        }
        border-bottom: 2px solid black;
    }

    .svg {
        visibility: hidden;
    }
}
@import '../../../Assets/circalind/_mixins.scss';

.phase-component {
    width: 100%;
}

.phase-navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 50px;
    margin-right: 50px;

    &.edit {
        display: block;
    }

    button {
        padding: 10px;
        margin-left: 20px;
        margin-right: 20px;
        width: 100px;
    }
}

.phase {
    border: 1px gray solid;
    border-radius: 15px;
    margin: 50px;
    margin-bottom: 10px;
    box-shadow: 0 0 1em gray;
    text-align: center;
    height: 620px;
    overflow-y: auto;

    .warning {
        font-weight: bold;
        font-size: larger;
        color: red;
    }

    .phase-question-box {
        height: 30vh;
    }

    .phase-introduction {
        display: grid;
        color: $circa-main-blue;

        .round-image {   
            height: 250px;
            width: 250px;
            padding: 10px;
            border-radius: 50%;
            box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
            justify-content: center;
            align-items: center;
            display: flex;
            margin-left: auto;
            margin-right: auto;
            img{
                width: 75%;
                height: 75%;
            }
            margin-bottom: 20px;
        }

        span {
            font-size: 150%;
        }
    }

}

.phase-start{
    border-radius: 15px;
    box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
    padding: 10px;
    margin: 10px;
    color: $circa-main-blue;
    background-color: white;

    .title {
        text-align: center;
        margin-bottom: 10%;
    }
    .phases {
        display: grid;
        grid-template-columns: repeat(5,auto);
        margin-bottom: 20px;

        .phase-image {
            height: 120px;
            width: 120px;
            padding: 10px;
            border-radius: 50%;
            box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
            justify-content: center;
            align-items: center;
            display: flex;
            img{
                width: 80%;
                height: 80%;
            }
        }
    }

    .texts {
        border-top: 1px solid black;
        display: grid;
        grid-template-columns: repeat(5,auto);
        margin: 10px;
        .description {
            display: grid;

            &.active {
                font-weight: bold;
            }
        }
    }

    .navigation {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-left: 50px;
        margin-right: 50px;
    }
}

.phase-end {
    height: 90%;

    .phase-navigation {
        margin-top: 15px;
        margin-bottom: unset;
    }

    .comments{
        padding: 10px;
        text-align: left;
        border-radius: 15px;
        border: 1px solid gray;
        height: 15vh;
        margin: 10px;
        textarea{
            width: 100%;
            height: 80%;
            border: 1px dotted gray;
            padding: 5px;
        }
    }

    .top{
        display: grid;
        grid-template-columns: 30% auto;
        padding: 10px;
        margin: 10px;

        .drivers {
            border-radius: 15px;
            border: 1px solid gray;
            padding: 5px;

            .select {
                display: grid;
                grid-template-columns: 20% auto;

                .image-select {
                    display: grid;

                    img{
                        padding: 5px;
                        width: 50px;
                        cursor: pointer;
                        &.selected {
                            border-bottom: 2px solid $circa-main-blue;
                        }
                    }
                }

                textarea{
                    border: 1px dotted gray;
                    margin: 5px;
                    padding: 5px;
                }
            }
        }

    }
}

.phase-header {
    display: flex;
    margin-left: 50px;
    margin-right: 50px;
    .phase-img {
        height: 50px;
        width: 50px;
        padding: 5px;
        border-radius: 50%;
        box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-top: -15px;
        margin-left: 10px;
        img{
            width: 80%;
            height: 80%;
        }
    }
    .phases {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-right: 20px;

        .phase-header-item {
            display: grid;
            grid-template-columns: auto auto;
            .text {
                .number {
                    &.selected {
                        border-bottom: 2px solid $circa-main-blue;
                    }
                }
                span{
                    padding-top: 10px;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
    .project-state {
        text-align: right;
        width: 150px;
        margin-left: 20px;
    }
}

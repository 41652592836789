.content-wrapper {
  padding: 10px;
  margin: 10px;
  border-radius: 15px;
  box-shadow: 0px 8px 10px #aaa;
  background-color: white;


  .preview-button-container {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }

  .owner-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
    border-radius: 15px;
    border: 1px solid black;
    padding: 10px;
  }

  .no-content {
    width: 100%;
    text-align: center;
    margin: 20px;
  }

  .heading {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;

    .version {
      display: flex;
      justify-content: flex-start;
    }

    .title {
      display: flex;
      justify-content: center;
    }

    width: 100%;
    text-align: right;
    margin-bottom: 5px;
  }
}

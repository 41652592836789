@import '../../Assets/circalind/mixins';

:root {
  background-color: #f8f8f8;
}

.content-editor-header {
  border-radius: 15px;
  border: 1px solid black;
  padding: 10px;

  .user-project-selection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .collapsed-info {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 150%;
    }

    img {
      height: 50px;
    }
  }

  .two-column {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 10px;

    .left-column {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .area {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;

    .header-element {
      text-align: center;
      min-width: 150px;
      padding: 10px;

      &.selected {
        color: $circa-main-blue;
        font-weight: bold;

        .header-image {
          box-shadow: 0px 8px 10px $circa-main-blue;
        }
      }

      .header-image {
        padding: 10px 20px 10px 20px;
        border-radius: 10px;
        margin-bottom: 20px;
        box-shadow: 0px 8px 10px #aaa;
        background-color: white;
        height: 80px;

        &:hover {
          cursor: pointer;
        }

        img {
          height: 100%;
          max-width: 100%;
        }

        span {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: bold;
        }
      }
    }
  }

  .project-specific {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .subarea-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;

    img {
      height: 30px;
      margin-right: 5px;
    }

    .subarea-selected {
      font-weight: bold;
    }

    div {
      box-shadow: 0px 8px 10px #aaa;
      padding: 10px 20px 10px 20px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    div:hover {
      cursor: pointer;
    }
  }

  .phases-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    span {
      margin-right: 10px;
    }

    .phase-element {
      &.selected {
        text-decoration: underline;
        color: $circa-main-blue;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .phase-separator {
      width: 10px;
      height: 50px;
      border-radius: 15px;
      border: 1px solid $circa-main-blue;
      display: inline-block;

      &.selected {
        background-color: $circa-main-blue;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .target {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    .target-separator {
      margin-right: 25px;
      margin-left: 25px;
    }
  }

  .start-button {
    display: flex;
    justify-content: center;
  }
}

.request-review-dialog {
  padding: 15px;
  display: grid;
  gap: 10px;
  min-width: 250px;
}

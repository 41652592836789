.synchronimeter-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .legend-item {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
    }
}

@import '../../../Assets/circalind/mixins';

.card {
    display: flex;
    flex-direction: column;
    color: $circa-main-blue;

    h2 {
        text-align: center;
    }

    .images {
        height: 100%;
        margin: 5px;
        padding: 15px;

        .image-wrapper {
            background-color: white;
            border-radius: 15px;
            border: none;
            box-shadow: 0px 0px 5px #333;
            margin: 5px;
            padding: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 150px;

            &:hover {
                cursor: pointer;
            }

            strong {
                margin-top: 5px;
                margin-bottom: 5px;
            }

            img {
                width: 50%;
            }
        }
    }


    .go-to-arrow {
        text-align: right;
    }

    background-color: white;
    border-radius: 15px;
    border: none;
    box-shadow: 0px 0px 5px #333;
    margin: 5px;
    padding: 15px;
}
.competence-question {
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 70vh;

    .content {
        .button-container {
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }
    }
}

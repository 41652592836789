.communication-overview {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 1fr;

    .content-card {
        height: 500px;

        h3 {
            height: 50px;
        }

        img {
            width: 100px;
        }
    }

}
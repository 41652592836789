@import '../..//Assets/mixins';

.projects {
    display: grid;
    grid-template-columns: 10% auto;
    height: 100%;
}

.new-button {
    display: flex;
    justify-content: space-between;
    margin: 30px;

    img {
        height: 75px;

        &:hover {
            cursor: pointer;
        }
    }
}

.projects-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;

    h3 {
        text-align: center;
    }

    .projects-list {
        .project-detail-wrapper {
            margin: 30px;


            .date {
                margin-left: 10px;
                margin-bottom: 10px;
            }

            .project-new-card {
                display: grid;
                text-align: center;
                margin-top: 10px;
                box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
                border-radius: 15px;
                padding: 10px;
                font-size: 30px;
                padding: 42px;
                span{
                    color: gray;
                }
                svg{
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 20px;
                    font-size: 80px;
                    color: $circa-main-blue;
                }
            }

            .project-detail-card {
                margin-top: 10px;
                box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
                border-radius: 15px;
                padding: 10px;

                .top-title {
                    color: gray;
                    padding: 10px;
                }

                .top-name {
                    padding: 10px;
                    font-weight: bold;
                    text-align: right;
                    margin-right: 10px;
                }

                .row-detail {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    align-items: end;

                    .actions {
                        margin-top: 10px;

                        .action-items {
                            display: flex;
                            justify-content: end;

                            .action {
                                margin-left: 5px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                span {
                                    margin-bottom: 5px;
                                }
                        
                                .action-image {
                                    height: 40px;
                    
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }

                    .quantity {
                        width: 100%;
                        text-align: right;
                        line-height: 100px;
                        color: $circa-main-blue;
                        span{
                            margin-right: 20px;
                            border-radius: 20px;
                            border: 2px solid $circa-main-blue;
                            padding: 15px;
                        }
                    }

                    .image-type {
                        display: flex;
                        align-items: center;

                        img {
                            height: 70px;

                            &.small {
                                height: 60px;
                            }
                        }
                    }

                    .phase-state {
                        display: block;
                        svg{
                            height: 50px;
                        }
                    }
                    .responsible {
                        text-align: right;
                        margin-right: 20px;
                        .top {
                            margin-right: 18px;
                            img {
                                margin-left: 10px;
                                height: 25px;
                            }
                        }
                        .bottom {

                        }
                        color: $circa-main-blue;
                    }
                }
            }
        }
    }

    .project-view {
        margin: 10px 50px 10px 50px;
        border-radius: 15px;
        border: 2px solid gray;
        padding: 10px;

        .project-header {
            display: grid;
            grid-template-columns: auto auto;

            .project-name {
                margin-top: auto;
                margin-bottom: auto;
            }

            .project-header-icons {
                text-align: right;

                svg {
                    margin: 10px;
                    height: 40px;
                    cursor: pointer;
                }
            }
        }

        .project-menu {
            display: grid;
            grid-template-columns: auto auto auto;
            grid-row-gap: 50px;

            .item {
                border: 1px solid gray;
                border-radius: 10px;
                display: grid;
                margin: 10px;
                padding: 10px;
                text-align: center;
                min-height: 100px;
                cursor: pointer;

                img {
                    margin-left: auto;
                    margin-right: auto;
                    height: 100px;
                }
            }
        }
    }
}

.clickable {
    &:hover {
        cursor: pointer;
    }
}
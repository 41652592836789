@import '../../Assets/circalind/_mixins.scss';

.info-bubble {
    padding: 1em 5em 2em 1em;
    border: 2px solid $circa-main-blue;
    position: relative;
    color: $circa-main-lightblue;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
    width: 15em;
    cursor: pointer;
    text-align: left;

    >.info-bubble-intro {
        position: absolute;
        right: 1em;
        top: 2em;
        font-weight: bold;
    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.55) 0px 7px 20px;
    }
}

.taxes {
    width: 750px;
    margin: 0 auto 10px auto;

    .tax-row {
        margin-bottom: 10px;
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 3fr 1fr 2fr;
        gap: 10px;
        align-items: center;

        label, input {
            width: 100%;
            box-sizing: border-box;
        }
    }

    .right {
        button {
            margin-left: 10px;
        }
    }
}
.managing-toolbox {
    padding: 25px;

    .header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;

        img {
            height: 100px;
        }
    }

}
@import '../../Assets/_mixins.scss';

.sub-area-editor {
  display: flex;
  flex-direction: column;

  input {
    margin: 0;
  }

  >*:nth-child(2n) {
    margin-bottom: 2rem;
  }

  .buttons {
    border-top: 1px solid #e5e5e5;
    margin-top: 1rem;
    padding-top: 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  .image-upload {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, auto);
    grid-template-rows: auto minmax(0, 1fr);
    grid-gap: 1rem;

    .invisible {
      visibility: hidden;
    }

    grid-template-areas:
      "select select"
      "source preview";

    .image-source-select {
      grid-area: select;
    }

    .image-upload-url {
      grid-area: source;
      align-self: center;
    }

    .image-upload-area {
      grid-area: source;
    }

    .image-preview {
      grid-area: preview;
      height: 100px;
      width: 100px;
      justify-self: center;
      align-self: top;

      >img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        border: none;
      }
    }
  }
}

.send-message {
    margin: 15px;

    .block {
        margin-bottom: 10px;
    }

    .button-container {
        width: 100%;
        display: flex;

        justify-content: center;
    }
}
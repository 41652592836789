.general-settings {
    width: 750px;
    margin: 0 auto 10px auto;

    .form-group {
        label {
            color: black;
            display: block;
        }

        input {
            width: 100%;
            box-sizing: border-box;
        }

        margin-bottom: 10px;
    }

    .right {
        button {
            margin-left: 10px;
        }
    }
}
@import "../../Assets/circalind/mixins";

.use-password-new {
  width: 40em;
  border: 2px solid gray;
  border-radius: 0.5em;
  background-color: white;
  box-shadow: 0px 8px 10px #00000059;

  .header {
    border-bottom: 1px solid black;
    padding: 0.5em 0.5em 0 0.5em;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 1.5em;
    text-align: center;
  }

  .warning {
    color: lightcoral;
    font-weight: bold;
  }

  .intro-text {
    padding: 0.5em;

    button {
      display: block;
      margin: auto;
    }
  }

  .set-new {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    align-items: center;
    padding: 0.5em;

    .text {
      grid-column: 1/3;
    }

    input {
      justify-self: end;
    }

    button {
      max-width: unset;
      margin: 5px 10px 5px 0;
    }

    .warning.reset {
      grid-column: 1/3;
      justify-self: center;
    }
  }

  .ask-for-password {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    align-items: center;
    padding: 0.5em;

    .text {
      grid-column: 1/3;
    }

    button {
      max-width: unset;
      margin: 5px 10px 5px 0;
    }

    input {
      justify-self: end;
    }
  }

  .invalid-password {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    padding: 0.5em;

    p {
      grid-column: 1/3;
    }

    button {
      max-width: unset;
      margin: 5px 10px 5px 0;
    }
  }
}

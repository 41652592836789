@import '../../Assets/circalind/_mixins.scss';

.decider-info-overview-grid {
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 0.5em;

    grid-template-areas:
        "l1 c1 r1"
        "l2 c2 r2"
        "l3 c3 r3"
        "l4 cc r4"
        "l5 cc r5"
        "l6 cc r6"
        "l7 cc r7";

    >*:nth-child(1) { grid-area: l1; justify-self: left;}
    >*:nth-child(2) { grid-area: l2; justify-self: left;}
    >*:nth-child(3) { grid-area: l3; justify-self: left;}
    >*:nth-child(4) { grid-area: l4; justify-self: left;}
    >*:nth-child(5) { grid-area: l5; justify-self: left;}
    >*:nth-child(6) { grid-area: l6; justify-self: left;}
    >*:nth-child(7) { grid-area: l7; justify-self: left;}

    >*:nth-child(8)  { grid-area: c1; justify-self: center;}
    >*:nth-child(9)  { grid-area: c2; justify-self: center;}
    >*:nth-child(10) { grid-area: c3; justify-self: center;}

    >*:nth-child(11) { grid-area: r1; justify-self: right;}
    >*:nth-child(12) { grid-area: r2; justify-self: right;}
    >*:nth-child(13) { grid-area: r3; justify-self: right;}
    >*:nth-child(14) { grid-area: r4; justify-self: right;}

    >.decider-info-center {
        grid-area: cc;
        justify-self: center;
        align-self: center;

        display: grid;
        grid-template-columns: repeat(2, 5em);
        grid-gap: 1em;

        >* {
            justify-self: center;
        }

        >*:nth-child(1), >*:nth-child(2) {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          grid-template-rows: auto minmax(0, 1fr);
          border-radius: 3px;
          height: 13em;
          font-size: 0.8em;
          padding: 0.5em;
          box-shadow: 0 0 0.5em 0.1em rgba(0,0,0,0.3);

          >*:nth-child(2) {
            align-self: end;
          }
        }

        img {
          width: 100%;
          height: 80px;
        }
    }

}

.compare-graphs {
    .graph-row {
    display: grid;
    grid-template-columns: 4fr 1fr 4fr;
    justify-items: center;
    }

    .graph-select {
        margin-top: 10px;
    }
}
@import '../../Assets/circalind/mixins';

.not-available {
    margin: 50px;
}

.info-service {
    display: grid;

    .rows {
        flex-direction: column;
        justify-content: space-evenly;
        display: flex;
        height: 100%;
    }

    .items {
        display: flex;
    }

    .service-menu {
        height: 75%;

        .menu-svg {
            display: block;
            width: 50%;
            height: 90%;
            margin-left: auto;
            margin-right: auto;

            .clickable-svg {
                &:hover {
                    animation: hoverOver 2s cubic-bezier(0.0, 0.1, 0.1, 2);
                }
            }
        }

        .menu-text {
            padding: 10px;
            border: 1px solid $circa-default-text;
            border-radius: 10px;
            position: absolute;
            font-size: x-large;
            font-weight: bold;
            width: 200px;
            text-align: center;
            display: none;

            &.smt1 {
                display: initial;
                color: $circa-information-red;
                right: 20%;
                top: 20%;
            }

            &.smt2 {
                display: initial;
                color: $circa-information-darkred;
                right: 25%;
                top: 75%;
            }

            &.smt3 {
                display: initial;
                color: $circa-information-blue;
                left: 25%;
                top: 45%;
            }

            &.smt4 {
                display: initial;
                color: $circa-information-lightblue;
                right: 15%;
                top: 55%;
            }

            &.smt5 {
                display: initial;
                color: $circa-information-orange;
                left: 22%;
                top: 20%;
            }

            &.smt6 {
                display: initial;
                color: $circa-information-lightgreen;
                right: 20%;
                top: 30%;
            }

            &.smt7 {
                display: initial;
                color: $circa-information-green;
                left: 22%;
                top: 65%;
            }
        }
    }

    @keyframes hoverOver {
        from {
            transform: scale(1);
            transform-origin: 50% 50%;
            transform-box: fill-box;
        }

        to {
            transform: scale(1.5);
            transform-origin: 50% 50%;
            transform-box: fill-box;
        }
    }

    @keyframes hoverOut {
        from {
            transform: scale(1.5);
            transform-origin: 50% 50%;
            transform-box: fill-box;
        }

        to {
            transform: scale(1);
            transform-origin: 50% 50%;
            transform-box: fill-box;
        }
    }

    .rows {
        margin-top: 5vh;
        .row{
            margin-left: 8vw;
            margin-top: 10px;
            width: 70vw;
            height: 20vh;

            .info-item-box {
                margin: 10px;
                border: 1px solid gray;
                box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
                height: 10vh;
                border-radius: 15px;
                text-align: center;
                padding-top: 5vh;

            }
            .slick-slide { text-align: center; }
        }
        .slick-slide {
            padding: 0 8px;
            box-sizing: border-box;
         }
    }
}
.order-view {
    max-width: 1000px;
    margin: 0 auto 25px auto;

    .order-item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-bottom: 10px;
        align-items: center;
    }

    .price-row {
        display: grid;
        grid-template-columns: 3fr 1fr;
        justify-items: end;
        margin-bottom: 5px;
    }

    .prices-section {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .buttons {
        button {
            margin-left: 10px;
        }
    }

    .agb {
        margin-bottom: 10px;

        label {
            color: black;
        }
    }
}

@import './Assets/mixins';

body {
	margin: 0;
	height: 100%;
	width: 100%;
	font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
	font-size: 18px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	#root {
		background-color: white;
	}

	footer {
		padding: 3px;
		position: fixed;
		bottom: 0px;
		width: 100%;

		@media only screen and (max-width: 500px) {
    		position: unset;
			bottom: unset;
    	}
	}
}

label {
	margin: 5px 10px 5px 0;
}

input {
	border-radius: 5px;
	padding: 10px;
	margin: 5px 10px 5px 0;
	font-size: 1rem;
}

button {
	display: inline-block;
	cursor: pointer;
	color: $invoice-button-color;
	font-family: Arial;
	font-size: 16px;
	padding: 16px 31px;
	text-decoration: none;
	max-width: 175px;
}

.right {
	text-align: right;
}

.left {
	text-align: left;
}

.center {
	text-align: center;
}
@import '../../Assets/circalind/mixins';

.regular-documentation {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 520px;

    .clickable {
        cursor: pointer;
    }

    .content-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        
        h3 {
            text-align: center;
            width: 100%;
        }

        .image-wrapper {
            display: flex;
            flex-direction: row;
            box-sizing: border-box;
            width: 100%;
            height: 100px;
            justify-content: center;
            img {
                max-height: 100%;
            }
        }

        .image-with-text {
            padding: 5px;
            border: 2px solid $circa-main-blue;
            display: flex;
            flex-direction: column;
            .image-row {
                display: flex;
                justify-content: flex-end;
                img {
                    width: 33%;
                }
            }
        }
    }
}
.new-staffing-user {
    min-width: 500px;
    max-height: 90vh;
    padding: 5px;
    overflow-y: scroll;

    .inputs {
        .input {
            display: flex;
            flex-direction: column;

            input {
                margin-right: 0;
            }
        }

        .roles {
            .role-list {
                display: flex;
                flex-direction: column;
                align-items: start;
                max-width: 90%;
                margin: auto;

                .role {
                    display: flex;
                    align-items: center;

                    img, svg {
                        margin-right: 5px;
                    }
                }
            }
        }

        .users {
            .add-user {
                margin-bottom: 10px;
            }

            .list {
                margin-bottom: 10px;
            }

            .add-button-container {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
            }
        }
    }

    .buttons {
        margin-top: 30px;
        display: flex;
        width: 100%;
        justify-content: flex-end;

        button {
            margin-left: 10px;
        }
    }

    .info {
        margin-top: 10px;
        display: block;
        width: 100%;
        text-align: center;
    }
}

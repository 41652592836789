.id-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .two-column {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 10px;
        padding: 20px;
        width: 100%;
    }

    .areas {
        max-width: 50%;
        text-align: center;
    }

    .profile-image {
        height: 250px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .navigate {
        font-weight: bold;

        &:hover {
            cursor: pointer;
        }
    }

    .choose-area {
        min-width: 500px;


        .area {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 10px;

            .roles {
                font-size: 130%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .card {
                display: flex;
                flex-direction: column;
                border-radius: 15px;
                border: none;
                box-shadow: 0px 0px 5px #333;
                padding: 15px;
                justify-content: space-between;
                align-items: center;
                width: 200px;
                height: 100px;

                &:hover {
                    cursor: pointer;
                }

                img {
                    width: 80px;

                    &.inactive {
                        filter: opacity(30%);
                    }
                }
            }
        }
    }
}

.compact-id {
    display: flex;
    flex-direction: column;
    align-items: center;

    .roles {
        .role {
            &:hover {
                cursor: pointer;
            }

            &.active {
                font-weight: bold;
            }
        }
    }


    h4 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .subarea {
        img {
            height: 25px;
        }
    }

}
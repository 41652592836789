@import '../../../Assets/circalind/mixins';

.circalind-app-header {
	display: grid;
	grid-template-columns: auto 100px;
	margin: 0px 0px 0px 0px;
	background-color: $circa-menu-background;
	border: 1px solid $circa-menu-border;

	.logo-div {
		max-height: 50px;
		display: flex;
		justify-content: end;

		img {
			max-height: 100%;
			padding: 5px;
			cursor: pointer;
		}
	}

	.logo-info {
		width: 50px;
		height: 40px;

		svg {
			width: 100%;
			height: 100%;
			color: $circa-dark-grey;
		}

		&.hidden {
			display: none;
		}
	}

	.menu {

		//Tablets
		@media only screen and (min-width: 1000px) {
			height: 50px;
			text-align: center;
		}

		.items {
			display: flex;
			position: relative;

			&.mobile {
				display: grid;
			}

			//Desktop and Tables
			@media only screen and (min-width: 1000px) {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				height: 100%;
			}

			.project {
				display: none;
				height: 100%;
				width: 100%;


				img {
					height: 40px;
					margin-right: 10px;
				}

				select {
					padding: 5px;
				}

				.assistance {
					margin-left: 10px;
					img {
						height: 30px;

						&:hover {
							cursor: pointer;
						}
					}
				}

				//Tablets
				@media only screen and (min-width: 1000px) {
					display: flex;
					align-items: center;
					padding-left: 10px;
				}

				&.mobile {
					display: flex;
					align-items: center;
					padding-left: 10px;
				}

				@media only screen and (min-width: 1000px) {
					display: flex;
					align-items: center;
					padding-left: 10px;
				}
			}

			.role-selection {
				min-width: 200px;
			}

			.item {
				margin: 5px;
				color: $circa-main-blue;
				text-decoration: none;
				white-space: nowrap;
				cursor: pointer;
				display: none;
				padding: 5px;
				border: 1.5px solid black;
				border-radius: 10px;
				min-width: 150px;
				background-color: white;

				&.disabled {
					pointer-events: none;
					color: darkgrey;
				}

				&.mobile {
					display: block;
				}

				//Tablets
				@media only screen and (min-width: 1000px) {
					display: block;
				}

				img {
					height: 50px;
					position: absolute;
					top: 0;
				}

				svg {
					font-size: 24px;
				}
			}

			.resp-icon {
				width: 20%;
				font-size: 30px;
				height: 100%;
				cursor: pointer;
				margin-top: 5px;
				margin-left: 10px;

				//Tablets
				@media only screen and (min-width: 1000px) {
					display: none;
				}
			}
		}
	}
}

.assistance-dialog {
	padding: 25px;

	.assistant {
		display: flex;

		.name {
			margin-left: 5px;
			font-weight: bold;
		}
	}
}
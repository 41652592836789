@import '../../Assets/circalind/mixins';

.collaberation {

    .info-page {
        padding: 20px;
        box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
        border-radius: 15px;
        height: 550px;
        text-align: center;
        margin-left: 5px;
        margin-right: 5px;
        color: $circa-main-blue;

        .collab-infos {
            display: grid;
            grid-template-columns: auto auto;

            .intro-col {
                &.left {
                    text-align: left;
                    .info {
                        display: flex;
                        margin-bottom: 10px;
                        .info-button {
                            max-height: 70px;
                            min-width: 300px;
                            display: grid;
                            border: 2px solid $circa-main-blue;
                            box-shadow: none;
                            .title {
                                text-align: left;
                                color: $circa-main-blue;
                            }
                            .intro {
                                text-align: right;
                                color: $circa-main-lightblue;
                            }
                        }
                        .button-info {
                            max-width: 300px;
                            font-size: 12px;
                            margin-left: 10px;
                            display: block;
                            h3{
                                margin: unset;
                            }
                            ul {
                                padding: unset;
                                margin-left: 10px;
                            }
                        }
                    }
                }
                &.right {
                    text-align: left;
                    margin-left: auto;
                    .info {
                        margin-bottom: 20px;
                        .info-button {
                            max-height: 70px;
                            min-width: 350px;
                            display: grid;
                            border: 2px solid $circa-main-blue;
                            box-shadow: none;
                            .title {
                                text-align: left;
                                color: $circa-main-blue;
                            }
                            .intro {
                                text-align: right;
                                color: $circa-main-lightblue;
                            }
                        }
                        .button-info {
                            max-width: 350px;
                            font-size: 12px;
                            margin-left: 10px;
                            display: block;
                            h3{
                                margin: unset;
                            }
                            ul {
                                padding: unset;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }

        .collab-welcome {
            text-align: center;

            display: flex;
            flex-direction: column;

            .blue-white {
                margin-top: 0px;
                margin-bottom: 10px;
                background-color: $circa-main-blue;
                color: white;
                font-weight: bold;
                border-radius: 10px;
                max-width: 200px;
                padding: 5px;
                margin-left: auto;
                margin-right: auto;
            }

            figure {
                position: relative;
                .welcome-logo {
                    margin-top: 50px;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 20px;
                    height: 100px;
                }
                .image-text {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    color: white;
                    font-weight: bold;
                }
            }



            h1{
                margin: 10px;
            }

            div {
                display: flex;
                justify-content: center;

                span {
                    font-weight: bold;
                    color: white;
                    background-color: $circa-main-blue;
                    border-radius: 10px;
                    padding: 10px;
                    margin-bottom: 5px;
                }
            }

            .lower {
                position: absolute;
                bottom: 10px;
                width: 100%;
                display: grid;
                grid-template-columns: repeat(5, auto);
                .col {
                    display: grid;
                    span{
                        margin: 5px;
                    }
                }
            }
        }

        .top-corner {
            position: absolute;
            right: -50px;
            height: 80px;
            top: -20px;
        }
        overflow-y: scroll;

        .steps-info {
            .actions {
                display: flex;
                justify-content: end;
                button {
                    margin-left: 10px;
                }
            }

            .steps {
                display: grid;
                grid-template-columns: repeat(5,20%);
                gap: 5px;
                .step-col {
                    font-size: 75%;
                    display: grid;
                    align-self: start;

                    button {
                        margin: auto;
                        margin-bottom: 10px;
                    }

                    ul {
                        text-align: left;
                    }

                    .step-logo {
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 10px;
                        height: 100px;
                        width: auto;
                    }

                    .additional {
                        text-align: left;
                    }

                    &.one {
                        margin-top: 50%;
                    }

                    &.two {
                        margin-top: 40%;
                    }

                    &.three {
                        margin-top: 30%;
                    }

                    &.four {
                        margin-top: 20%;
                    }

                    &.five {
                        margin-top: 10%;
                    }
                }
            }
        }
    }

    .navigation {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        width: 70vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;

        button {
            padding: 10px;
            margin-left: 0px;
            margin-right: 0px;
            width: 100px;
        }
    }
}

.my-editor {
    padding: 25px;
    button {
        box-shadow: none;
        background-color: unset;
    }
}
@import '../../../../Assets/circalind/_mixins.scss';

.compass-question {
    display: grid;
    grid-template-columns: 20% auto;
    height: 100%;

    .compass-description {
        padding: 15px;

        h3 {
            margin-left: auto;
            margin-right: auto;
        }

        span {
            margin-top: 20px;
        }

        img {
            width: 200px;
        }
    }

    .compass-center {
        width: 98%;

        &.edit {

            .edit-toolbar {

                button {
                    padding: 8px;
                }

            }
        }

        .graph {
            height: 25%;

            .question-graph {
                width: 95%;
                user-select: none;
            }

            .graph-items {
                height: 100%;
                display: grid;
                grid-template-columns: 20% auto;

                .graph-legend {

                    ul {
                        padding-left: 10px;

                        li {
                            display: flex;
                            padding: 3px;

                            .color-input {
                                width: 50px;
                                border: unset;
                            }

                            .text-input {
                                padding: 2px;
                                margin: 2px;
                                max-width: 150px;
                            }

                            .color-preview {
                                width: 20px;
                            }


                        }
                    }
                }
            }
        }

        .compass-comments {
            display: grid;
            border: 1px solid $circa-dark-border;
            padding: 5px;
            margin-top: 20px;
            border-radius: 10px;
            grid-template-rows: 25px 25px 80px 25px 80px 25px 80px 40px;

            label {
                color: $circa-main-blue;
            }

            textarea {
                border: unset;
                border: 1px solid black;
                border-radius: 15px;
                padding: 10px;
            }

            .to-my-way {
                text-align: center;
            }
        }

        .compass-nav {
            display: grid;
            grid-template-columns: 10% auto 10%;
        }
    }

}

.add-row {
    text-align: left;
    margin-left: 20px;
}

.add-col {
    text-align: right;
    margin-right: 20px;
}

.compass-table {
    width: 100%;

        tr {
            height: 80px;
            background-color: unset;

            &.edit-row-menu {
                height: 30px;
            }
        }

        &.edit-row {
            width: 50px;
        }

        td {
            height: 1px;
            padding: 15px;
            &.empty-cell {
                padding: 20px;
                border: 1px solid lightgray;
                text-align: center;
                cursor: pointer;
            }

            &.edit-col {
                padding: 5px;
                text-align: center;
            }

            &.edit-row {
                width: 10px;
                padding: 5px;
                text-align: center;
            }

            .cell-item {
                height: 100%;
                display: inline-table;

                .cell-wrapper {
                    display: inline-flex;
                    width: 100%;
                    height: 100%;
                }

                .answers {
                    display: grid;
                    grid-template-columns: auto auto;
                    .user {
                        font-weight: bold;
                        margin-top: 5px;
                        margin-right: 10px;
                    }
                    .answer {
                        margin-left: 20px;
                        margin-top: 5px;
                    }
                }

                &.edit {
                    width: 100%;

                    .szh-menu-button {
                        background-color: unset;
                        padding: unset;
                        border: unset;
                        font-size: 130%;
                    }
                }

                &.text {
                    width: 100%;
                    height: 100%;
                    margin: unset;
                    position: relative;

                    svg {
                        position: absolute;
                        right: 5px;
                        top: 5px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                &.staticimage {
                    .static-image {
                        .static-Image{
                            height: 250px;
                        }
                    }
                }

                &.imageupload {
                    .image-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        img{
                            width:50%;
                        }
                    }
                }

                &.boolean {
                    .yes-no {
                        label {
                            color: black;
                        }
                    }
                }

                &.multichoice{
                    .multi-choice {
                        .option {
                            cursor: pointer;
                            display: flex;
                        }
                    }
                }

                &.multifile {
                    .multi-files {
                        width: 100%;
                        .add{
                            text-align: center;
                            svg{
                                cursor: pointer;
                                text-align: center;
                            }
                        }
                        .desk-item {
                            padding: 5px;
                            margin: 5px;
                            border-radius: 10px;
                            box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
                            display: flex;
                            flex-direction: column;

                            .desk-item-title {
                                display: flex;
                                align-items: center;

                                img {
                                    height: 1em;
                                }
                            }

                            .actions {
                                display: flex;
                                justify-content: end;

                                .action {
                                    margin-left: 5px;
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                        .new-desk-item {
                            text-align: center;
                            svg{
                                cursor: pointer;
                                text-align: center;
                            }
                        }
                    }
                }

                &.phaseicon {
                    .phase-icon {
                        display: flex;
                        justify-content: start;
                        margin-left: 20%;
                        img {
                            max-width: 40%;
                        }
                    }
                }

                .szh-menu-button {
                    width: 20px;
                    height: 50px;
                    padding: 5px;
                    margin: 5px;
                    border: none;
                    box-shadow: none;
                    border-radius: unset;

                    .szh-menu__item {
                        display: grid;
                        grid-template-columns: 20% auto;
                        svg{
                            padding: 5px;
                            
                        }
                    }
                }
            }

            textarea {
                font-size: 100%;
                min-height: 2em;
                height: 100%;
                box-sizing: border-box;
                width: 100%;
                resize: none;
                border: 1px solid black;
                border-radius: 15px;
                padding: 10px;

                &.readonly {
                    outline: none;
                    background: transparent;
                    border: unset;
                }
            }

            .cell-menu {
                margin: 10px;
            }
        }
    
    .add-col {
        text-align: right;

        svg {
            position: relative;
            right: -20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .add-row {
        text-align: left;

        svg {
            position: relative;
            left: -20px;
            height: 20px;
            cursor: pointer;
        }
    }

}

.expanded-dialog {
    height: 80vh;
    width: 80vw;
    padding: 15px;

    textarea {
        border-radius: 15px;
        padding: 10px;
        margin: 15px;
        width: calc(100% - 50px);
        height: calc(100% - 50px);
    }
}

.style-dialog {
    padding: 15px;

    select {
        padding: 5px;
        font-size: 110%;
        margin-right: 10px;
    }

    .form-element {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
}
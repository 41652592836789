.one-view {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100px;

    .base {
        img {
            max-width: 100%;
            height: 50px;
        }
    }

    .projects {
        display: block;
        width: 100%;
        grid-column: 1;
        grid-row: 1 / 3;
    }

    .personal-competences {
        grid-column: 2;
        grid-row: 1;

    }

    .content {
        display: flex;
        justify-content: center;

        .synchro {
            display: grid;
            justify-content: center;
            justify-items: center;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    .project-competences {
        grid-column: 2;
        grid-row: 2;
    }

    .navigation {
        grid-column: 1 / 3;
        grid-row: 3;
        padding-bottom: 50px;
    }

    .importance-dialog {
        .edit-importance {
            display: flex;
            align-items: center;
            padding: 20px;
        }
    }

    .clickable {
         &:hover {
            cursor: pointer;
        }
    }
}

.project-overview {
    width: 100%;
    padding-bottom: 25px;

    h3 {
        text-align: center;
    }

    .project-list {
        margin-top: 10px;

        .project {
            box-shadow: 0px 0px 5px grey;
            border-radius: 5px;
            padding: 5px;
            margin-top: 5px;

            display: flex;
            align-items: center;

            img {
                height: 30px;
            }

            .name {
                display: grid;
                grid-template-columns: 15px 15px auto;
                gap: 5px;
                width: 100%;
                align-items: center;
            }

            .icons {
                width: 100%;
                text-align: right;

                svg {
                    margin-left: 5px;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .selection {
        display: flex;
        box-shadow: 0px 0px 5px grey;
        border-radius: 5px;
        margin: 5px;
        
        span {
            width: 100%;
            text-align: center;
            padding: 5px;
            border-left: 1px solid black;
            user-select: none;

            &.active {
                font-weight: bold;
            }

            &:first-child {
                border: none;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.clone-project-dialog {
    padding: 25px;
}
.admin-customer-overview {
    max-width: 1000px;
    margin: 0 auto 25px auto;

    .buttons {
        display: grid;
        grid-template-columns: 5fr 2fr 3fr 3fr;
        width: 100%;
        justify-content: right;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;

        button {
            max-width: 30em;
            margin-left: 10px;
        }
    }
}
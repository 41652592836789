.change-room-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 15px;
    height: 520px;

    .card {
        display: flex;
        flex-direction: column;

        h2 {
            text-align: center;
        }

        .description {
            height: 100%;
        }

        .go-to-arrow {
            text-align: right;
        }

        border-radius: 15px;
        border: none;
        box-shadow: 0px 0px 5px #333;
        margin: 5px;
        padding: 15px;

        .card-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .card:hover {
                cursor: pointer;
            }

            .card {
                display: flex;
                justify-content: space-between;
                align-items: center;
                &.with-image {
                    img {
                        width: 80px;
                    }

                    .text-center {
                        text-align: center;
                    }
                }
            }
        }
    }
}
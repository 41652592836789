.phase-overview {
    padding: 10px;

    h3 {
        text-align: center;
    }

    .cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}
.dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 90vh;
    overflow: auto;
    
    z-index: 700;
    outline: 0;
    border: 1px solid black;
    background-color: white;
    padding: 10px;
    border-radius: 15px;

    .dialog-top {
        text-align: right;
        position: absolute;
        right: 20px;

        .dialog-close-fa {
            cursor: pointer;
        }
    }
}

.overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
}
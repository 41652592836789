.new-staffing-group {
    min-width: 500px;
    .inputs {
        .input {
            display: flex;
            flex-direction: column;

            input {
                margin-right: 0;
            }
        }

        .users {
            .add-user-panel {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, auto);
                grid-gap: 0.5em;
                align-items: center;

                .add-button {
                    cursor: pointer;
                }
            }

            .user-entry {
                cursor: default !important;
                label {
                    cursor: default !important;
                }
            }

            .list {
                margin: 10px 0px;
            }
        }
    }

    .buttons {
        margin-top: 30px;
        display: flex;
        width: 100%;
        justify-content: flex-end;

        button {
            margin-left: 10px;
        }
    }
}

.quality-description {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 50px;
    margin-right: 50px;

    .quality-column {
        display: flex;

        img {
            height: 100px;
            margin-right: 5px;
        }
    }

    padding-bottom: 10px;
    border-bottom: 1px solid black;
}

.quality-timeline {
    display: grid;
    margin-top: 10px;
    margin-right: 50px;
    grid-template-columns: repeat(5, 1fr);
    justify-content: space-between;
}
@import '../../Assets/_mixins.scss';

.sub-area-line {
  div.edit-mode-button, div.add-sub-area-button {
    box-shadow: none;
    padding: 0;
    cursor: pointer;
    align-self: center;

    &.hidden {
      display: none;
    }
  }

  >.sub-area-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
    margin: 2rem 0;
  }


  div.sub-area {
    display: grid;
    grid-template-columns: auto minmax(0, auto) minmax(0, auto);
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-areas:
      "image name edit"
      "image name delete"
    ;

    grid-gap: 0;
    padding: 0;
    box-shadow: 0px 8px 10px #aaa;
    border-radius: 10px;
    align-items: center;
    border: 2px solid transparent;
    cursor: pointer;
    user-select: none;

    &.selected {
      border: 2px solid $circa-main-blue;
    }

    >img {
      grid-area: image;
      padding: 0.7rem;
      height: 30px;
    }

    >span {
      grid-area: name;
      padding: 0.7rem;
    }

    >.sub-area-edit-button {
      grid-area: edit;
      border-radius: 0 0.3rem 0 0;
      padding: 0.3rem 0.3rem 0.7rem 0.4rem;
    }
    >.sub-area-delete-button {
      grid-area: delete;
      border-radius: 0 0 0.3rem 0;
      padding: 0.7rem 0.3rem 0.3rem 0.4rem;
    }

    >.sub-area-delete-button, >.sub-area-edit-button {
      cursor: pointer;

      &.hidden {
        display: none;
      }

      background-color: $circa-menu-background;
    }
  }
}

.project-desk {

    .search-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .actions {
            display: flex;
            align-items: stretch;

            img.corner-img {
                max-height: 50px;
                padding: 10px;
            }

            button {
                margin-left: 10px;

                img {
                    max-width: 80%;
                }

                &.no-padding {
                    padding: 10px;
                }

                a {
                    color: black;
                }

                &:disabled {
                    a {
                        color: lightgrey;
                    }
                }
            }
        }
    }

    .phase-header {
        margin-top: 20px;
    }

    .content-wrapper {
        margin: 50px;
        margin-top: 10px;
        border-radius: 15px;
        box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
        padding: 10px;
    }

    .detailProject {
        .abort {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
    }


    .way {
        display: flex;
        justify-content: end;
        align-items: center;

        button img {
            height: 50px;
        }
    }
}

.tag-list {
    .tag {
        background-color: lightgrey;
        padding: 10px;
        border-radius: 10px;
        margin-right: 5px;

        .clickable {
            margin-left: 5px;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.tag-dialog {
    padding: 25px;
}

.share-project-dialog {
    overflow: visible;
}

.share-project-dialog-inner {
    padding: 15px;
    overflow: visible;

    .button-container {
        margin-top: 10px;
        display: flex;
        justify-content: end;
    }
}
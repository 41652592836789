@import '../../../../Assets/circalind/_mixins.scss';

.post-phase-selection {
    text-align: center;

    .warning {
        margin-top: 20px;
        font-size: xx-large;
        font-weight: bold;
        text-align: center;
        color: crimson;
        width: 100%;
    }

    .post-phases {
        display: grid;
        grid-template-columns: repeat(3, 33%);

        .post-phase-card {
            box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
            border-radius: 15px;
            padding: 20px;
            text-align: right;
            margin: 20px;
            display: grid;
            grid-template-rows: 2fr 1fr 5fr 1fr;
            height: 60vh;

            h2 {
                text-align: left;
                color: gray;
            }
            span{
                color: gray;
                padding: 5px;
            }

            button {
                padding: 5px;
                min-width: 50px;
                color: gray;
                box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
            }

            .round {
                margin-bottom: 30px;
                height: 200px;
                width: 200px;
                padding: 0px;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-left: auto;
                margin-right: auto;

                img{
                    width: auto;
                    height: 100%;
                }
            }
        }
    }
}

.post-Phase {
    padding: 25px;

    h3 {
        text-align: center;
    }

    .edit-tool-bar {
        text-align: right;
        margin: 20px;
        button{
            margin: 10px;
        }
    }

    .post-phase-header {
        display: grid;
        grid-template-columns: 1fr 10fr;

        .round {
            margin-top: 0px;
            margin-bottom: 0px;
            height: 100px;
            width: 100px;
            padding: 10px;
            border-radius: 50%;
            box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
            justify-content: center;
            align-items: center;
            display: flex;
            margin-left: auto;
            margin-right: auto;

            img{
                width: 80%;
                height: 80%;
            }
        }
    }

    .post-Phase-body {
        .content-box {
            border-radius: 10px;
            border: 1px solid black;
            padding: 10px;
            max-width: 1000px;
            margin: auto;
            text-align: center;
        }
    }
}
.choose-license {
    max-width: 1000px; 
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .select-container {
        margin-bottom: 10px;
        width: 100%;
    }

    .actions {
        margin-bottom: 10px;
        margin-top: 10px;
        display: flex;

        button {
            margin-left: 10px;
        }
    }
}
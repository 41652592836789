.synchronimeter-values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .values-item {
        margin: 0.3em 0;
        display: grid;
        grid-template-columns: minmax(0, auto) minmax(0, 1fr) 7em;
        grid-gap: 1em;
        align-items: center;
        >* {
            margin: 0;
        }
    }
}

@import '../../Assets/circalind/mixins';

.tab-view {
    .tab-header {
        width: 100%;
        display: flex;
        justify-content: center;

        .tab {
            width: 100px;
            display: flex;
            flex-direction: row;
            justify-content: center;

            img {
                height: 30px;
            }

            &:hover {
                cursor: pointer;
            }

            &.active {
                box-shadow: 0px 0px 5px $circa-main-blue;
            }
        }
    }
}
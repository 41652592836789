.admin-admin-page {
    max-width: 1000px;
    margin: 0 auto 0 auto;

    .form-element {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 2fr;
        width: 100%;
        justify-content: right;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;

        button {
            max-width: 30em;
            margin-left: 10px;
        }
    }
}
.basic-table {
    border-collapse: collapse;
    width: 100%;
    white-space: nowrap;


    th {
        white-space: break-spaces;
    }

    th,
    td {
        border: 1px solid black;
        padding: 5px;
    }
}

.table-wrapper {
    width: 100%;
    overflow-x: scroll;
}
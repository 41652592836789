.polls {
    padding: 25px;

    h1{
        text-align: center;
    }

    .polls-header {
        display: flex;
        justify-content: end;
    }

    .poll {
        margin-top: 25px;

        .poll-header {
            display: flex;
            justify-content: space-between;
        }

        .audience {
            margin-top: 10px;
        }

        .edit-tools {
            display: flex;
            justify-content: end;
            button {
                margin-right: 15px;
                max-width: 250px;
            }
        }
    }
}
@import '../../../../Assets/circalind/_mixins.scss';

.pre-Phase {
    .edit-tool-bar {
        text-align: right;
        margin: 20px;
        button{
            margin: 10px;
        }
    }
    .pre-Phase-body {
        display: grid;
        grid-template-columns: 20% auto;

        .pre-phase-card {
            box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
            border-radius: 15px;
            padding: 20px;
            text-align: right;
            margin: 20px;
            overflow: hidden;

            h2 {
                text-align: center;
                overflow: hidden;
                color: gray;
            }
            span{
                color: gray;
                padding: 5px;
            }

            button {
                padding: 5px;
                min-width: 50px;
                color: gray;
                box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
                &.required {
                    color: $circa-main-blue;
                    border: 2px solid $circa-main-blue;
                    box-shadow: -2px 2px 5px $circa-main-blue, 2px 2px 5px $circa-main-blue
                }
            }

            .round {
                margin-top: 100px;
                margin-bottom: 30px;
                height: 125px;
                width: 125px;
                padding: 10px;
                border-radius: 50%;
                box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-left: auto;
                margin-right: auto;

                img{
                    width: 80%;
                    height: 80%;
                }
            }
        }
    }
}

.pre-phase-selection {
    text-align: center;

    .warning {
        margin-top: 20px;
        font-size: xx-large;
        font-weight: bold;
        text-align: center;
        color: crimson;
        width: 100%;
    }

    .pre-phases {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 2fr 1fr;

        .pre-phase-card {
            box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
            border-radius: 15px;
            padding: 20px;
            text-align: right;
            margin: 20px;
            display: grid;
            grid-template-rows: 2fr 1fr 5fr 1fr;
            height: 60vh;


            h2 {
                text-align: left;
                color: gray;
            }
            span{
                color: gray;
                padding: 5px;
            }

            button {
                padding: 5px;
                min-width: 50px;
                color: gray;
                box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
                &.required {
                    color: $circa-main-blue;
                    border: 2px solid $circa-main-blue;
                    box-shadow: -2px 2px 5px $circa-main-blue, 2px 2px 5px $circa-main-blue
                }
            }

            .round {
                height: 150px;
                width: 150px;
                padding: 10px;
                border-radius: 50%;
                box-shadow: -2px 2px 5px gray, 2px 2px 5px gray;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-left: auto;
                margin-right: auto;

                &:hover {
                    cursor: pointer;
                }

                img{
                    width: 80%;
                    height: 80%;
                }
            }
        }
    }
}
.clickable {
    &:hover {
        cursor: pointer;
    }
}

.small {
    width: 200px;
    img {
        height: 100px;
    }
}

.project-base {
    .button-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .start {
        img {
            max-height: 250px;
        }
    }
}

.edit-dialog {
    padding: 20px;
    display: flex;
    align-items: center;

    label {
        margin-left: 5px;
    }

    button {
        margin-left: 10px;
    }
}

.importance {
    font-weight: bold;
    font-size: 30px;

    .A {
        color: red;
    }
    .B {
        color: yellow;
    }
    .C {
        color: green;
    }
}